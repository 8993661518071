<template>
  <div class="h-100">
    <div v-if="history.length > 0" class="activity-feed mt-6">
      <div
        class="d-flex align-center feed-item"
        v-for="(item, index) in history"
        :key="index"
      >
        <div class="details">
          <p class="mb-0">
            {{
              formatTransactionType(item.transaction_type, item.daily_interest)
            }}
          </p>
          <small>{{ formatDate(item.created_at) }} </small>
        </div>

        <div class="ml-auto">
          <p
            class="mb-0 font-weight-bold"
            :class="isCreditOrDebit(item.transation_mode)"
            v-if="item.transaction_amount"
          >
            N{{ formatAsMoney(item.transaction_amount) }}
          </p>
          <small :class="isCreditOrDebit(item.transation_mode)">
            {{ item.transation_mode }}
          </small>
        </div>
      </div>

      <infinite-loading spinner="waveDots" @infinite="infiniteHandler">
        <span slot="no-results"></span>
        <span slot="no-more"></span>
      </infinite-loading>
    </div>

    <div
      v-if="history.length == 0 && !loading"
      class="d-flex mt-10 h-100 justify-center align-center"
    >
      <p>No Transaction history</p>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      page: 1,
      loading: false,
      history: [],
    };
  },
  computed: {
    ...mapState("history", ["moduleName"]),
    ...mapState(["userDetails"]),
  },
  components: {
    InfiniteLoading,
  },
  async mounted() {
    await this.fetchTransactions();
  },
  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions(["getSavedWallet"]),
    ...mapActions("history", ["getTransactions"]),

    formatModuleName(name) {
      return name[0].toUpperCase() + name.slice(1).toLowerCase();
    },

    formatTransactionType(transaction_type, daily_interest) {
      if (this.moduleName === "COMMISSION") {
        return "Referral Bonus";
      }
      if (this.moduleName === "INTEREST") {
        if (transaction_type === "liquidation_fee") {
          return "Liquidation fee";
        }
        if (daily_interest) {
          const walletId = daily_interest.wallet_id;
          const wallet = this.userDetails.customer.wallets.find(
            (item) => item.id === walletId
          );

          return (
            wallet.wallet_type[0] +
            wallet.wallet_type.slice(1).toLowerCase() +
            " " +
            "Interest"
          );
        }
        return "Interest";
      }
      if (transaction_type == "ajo_charge") {
        transaction_type = transaction_type.split("_")[1];
      }

      if (transaction_type == "wallet_withdrawal") {
        transaction_type = transaction_type.split("_")[1];
      }

      return this.formatModuleName(this.moduleName) + " " + transaction_type.replace('_', ' ');
    },

    isCreditOrDebit(item) {
      if (item === "debit") return "error--text";
      else return "secondary--text";
    },

    async fetchTransactions() {
      try {
        this.loading = true;
        const wallet = await this.getSavedWallet(this.moduleName);
        let { payload } = await this.getTransactions({
          wallet_id: Number(wallet.id),
          status: "success",
          page: this.page,
        });
        this.history = this.history.concat(payload.rows);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    infiniteHandler($state) {
      setTimeout(async () => {
        this.page++;
        let data = await this.fetchTransactions();
        if (data && data.length > 0) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
