<template>
  <div class="onboarding d-flex flex-column justify-center">
    <div class="onboarding-slide d-flex">
      <div
        class="d-flex flex-column align-center slide"
        v-for="i in slideData"
        :key="i.title"
      >
        <div class="text-center image-wrapper">
          <v-img :src="i.url" class="icons align-self-start"></v-img>
        </div>
        <div class="text-center mb-4 caption">
          <h2 class="mt-7 mb-3">{{ i.title }}</h2>
          <p class="">
            {{ i.description }}
          </p>
        </div>
      </div>
    </div>

    <div class="text-center">
      <div class="d-flex mt-5 justify-center">
        <div
          class="indicators mr-2"
          v-for="i in 4"
          :key="i"
          :class="activeIdx + 1 == i ? 'active' : ''"
        ></div>
      </div>

      <fj-base-button
        class="mt-15"
        @click="animateSlide"
        v-if="activeIdx < 3"
        hasIcon
      >
        Next</fj-base-button
      >
      <div class="d-flex justify-center" v-else>
        <router-link to="/login">
          <fj-base-button class="mt-15 mr-4"> Login</fj-base-button>
        </router-link>
        <router-link to="/register">
          <fj-base-button class="mt-15"> Signup</fj-base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIdx: 0,
      slideData: [
        {
          url: require("@/assets/images/svg/safe-box.svg"),
          title: "Safe and Secure",
          description:
            " FaJo monie is the most secured savings platform, we guarantee youvalue for your money.",
        },
        {
          url: require("@/assets/images/svg/rocket-icon.svg"),
          title: "Save small, Earn big",
          description: " Save for projects, earn high returns on your savings.",
        },
        {
          url: require("@/assets/images/svg/calculator.svg"),
          title: "Interest-free loans",
          description:
            "Enjoy access to interest free loans in case of emergencies while running your savings plan simultaneously",
        },
        {
          url: require("@/assets/images/svg/bonus-icon.svg"),
          title: "Refer and Earn",
          description:
            "Enjoy referral bonus everytime a new user registers via your code.",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    animateSlide() {
      this.activeIdx++;
      console.log(this.activeIdx);
      let slideWrapper = document.getElementsByClassName("onboarding-slide")[0];
      console.log(slideWrapper, this.activeIdx);
      slideWrapper.style.marginLeft = -(100 * this.activeIdx) + "vw";
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding {
  height: 100vh;
  overflow: hidden;

  .onboarding-slide {
    width: 400vw;
    transition: 0.5s ease-in-out;

    .slide {
      width: 100vw;
    }
  }
  .image-wrapper {
    width: 70%;
    .icons {
      width: 100%;
      height: 100%;
    }
  }

  p {
    width: 80%;
    margin: 0 auto;
  }

  .indicators {
    width: 10px;
    height: 5px;
    background: #c4c4c4;

    &.active {
      width: 17px;
      background: var(--primary);
    }
  }
}
</style>
