<template>
  <div class="mt-12">
    <v-row v-if="!loading">
      <v-col v-if="loanStatus" cols="12" md="4">
        <section class="white py-10">
          <div class="text-center">
            <h1 class="black--text header-amount">
              ₦
              {{ getLoanHeader().amount }}
            </h1>
            <small>{{ getLoanHeader().text }}</small>
          </div>

          <div class="d-flex justify-space-between mt-4 px-9">
            <div class="text-center">
              <small>{{ getLoanAmount().text }}</small>
              <h5>₦ {{ getLoanAmount().amount }}</h5>
            </div>

            <div class="text-center">
              <small>{{ getLoanDuration().text }}</small>
              <h5>{{ getLoanDuration().duration }} Month(s)</h5>
            </div>
          </div>

          <div v-if="ongoingLoan" class="mt-4">
            <div class="text-center">
              <small class="d-inline mr-2">Monthly Payment:</small>
              <h5 class="d-inline">₦ {{ formatAsMoney(monthlyRepayment) }}</h5>
            </div>
          </div>
        </section>

        <fj-base-button
          v-if="loanStatus && !loanStatus.ongoing_loan"
          block
          class="mt-4"
          @click="initiateRequest"
        >
          Request
        </fj-base-button>

        <fj-base-button
          v-else-if="loanStatus && showLoansRepaymentBtn()"
          block
          class="mt-4"
          @click="initiatePayment"
        >
          Make Payment
        </fj-base-button>

        <fj-base-button v-else disabled outlined block class="mt-4">
          Awaiting Disbursement
        </fj-base-button>
      </v-col>
      <v-col v-if="false" cols="12" md="4">
        <fj-product-description
          text="Enjoy minimal interest loan for as long as 6months."
          subtitle="Please Keep in mind that if you want to borrow more than what you're 
          eligible for, you'll be asked to provide the phone number of a surety who is 
          already a registered member of Fajo."
          color="black"
          :icon="require('@/assets/images/svg/percent.svg')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <history :defaultTabs="tabs" defaultEntity="emergency_funds"></history>
      </v-col>
    </v-row>

    <fj-page-loader v-else></fj-page-loader>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { formatAsMoney } from "@/utils/helpers";
import history from "@/components/history/history.vue";
export default {
  data() {
    return {
      loading: false,
      tabs: [
        {
          title: "My Requests",
          entity: "emergency_funds",
        },
        {
          title: "Payments",
          entity: "emergency_funds_payment",
        },
      ],
    };
  },

  computed: {
    ...mapState(["userDetails"]),
    ...mapState("loan", ["loanStatus"]),
    ongoingLoan() {
      return this.loanStatus?.ongoing_loan || null;
    },
    monthlyRepayment() {
      return this.ongoingLoan?.monthly_payment?.toFixed() || 0
    }
  },

  async mounted() {
    await this.fetchLoanRequest();
  },

  components: {
    history,
  },

  methods: {
    formatAsMoney,
    ...mapActions("loan", ["getLoanStatus"]),

    async fetchLoanRequest() {
      try {
        this.loading = true;
        await this.getLoanStatus();
        this.loading = false
      } catch (err) {
        this.loading = false;
      }
    },

    initiateRequest() {
      if (
        !this.loanStatus.max_loan_amount ||
        this.loanStatus.max_loan_amount == 0
      ) {
        this.$store.dispatch("snackbar/openSnackbar", {
          text: "Sorry, you aren't eligible to make an emergency fund request",
        });
      } else {
        this.$router.push({ name: "Emergency Funds Request" });
      }
    },

    initiatePayment() {
      this.$router.push({ name: "Emergency Funds Payment" });
    },

    getLoanHeader() {
      const loan = this.loanStatus.ongoing_loan;
      const res = {
        amount: this.formatAsMoney(this.loanStatus.max_loan_amount),
        text: "Eligible Amount",
      };

      if (loan) {
        if (loan.status === "disbursed" || loan.status === "defaulted") {
          // res.text = "Amount borrowed(Including interest)";
          // res.amount = this.formatAsMoney(
          //   loan.loan_amount + loan.interest_amount
          // );
          res.text = "Amount Left"
          res.amount = this.formatAsMoney(loan.amount_left)
        } else if (loan.requires_surety && loan.surety_status === "approved") {
          res.text = "Amount Requested";
          res.amount = this.formatAsMoney(loan.loan_amount);
        }
      }

      return res;
    },

    getLoanAmount() {
      const loan = this.loanStatus.ongoing_loan;
      const res = {
        text: "Amount collected",
        amount: "0",
      };
      if (loan) {
        res.amount = this.formatAsMoney(loan.loan_amount);
        if (loan.status === "disbursed" || loan.status === "defaulted") {
          res.text = "Amount Paid";
          res.amount = this.formatAsMoney(loan.amount_paid);
        }
        if (
          loan.status === "pending" ||
          loan.status === "processing" ||
          loan.status === "disbursement_failed"
        ) {
          res.text = "Amount Requested";
          res.amount = this.formatAsMoney(loan.loan_amount);
        }
      }

      return res;
    },

    getLoanDuration() {
      const loan = this.loanStatus.ongoing_loan;
      const res = {
        text: "Duration",
        duration: "0",
      };
      if (loan) res.duration = loan.duration;
      return res;
    },

    showLoansRepaymentBtn() {
      const loan = this.loanStatus.ongoing_loan;
      if (
        loan &&
        (loan.status === "disbursed" || loan.status === "defaulted")
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.thrift-color {
  background: #206d5f;

  &__text {
    color: #206d5f;
  }
}
.header-amount {
  font-size: 2rem;
}
.history {
  height: 72vh;
  overflow: auto;

  @media screen and (max-width: 768px) {
    height: 50vh;
  }
}
</style>
