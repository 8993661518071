<template>
  <div class="profile-views">
    <div class="white py-8 px-6 mt-10">
      <p><b>Secure your account</b></p>
      <br />
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="4" class="py-0">
            <fj-base-input
              label-outside="Old PIN"
              v-model="form.old_password"
              password
              :rules="[rules.required]"
              placeholder="Enter Old PIN"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <fj-base-input
              label-outside="New PIN"
              password
              v-model="form.new_password"
              :rules="[rules.required]"
              placeholder="Enter New PIN"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <fj-base-input
              label-outside="Confirm New PIN"
              password
              :rules="confirmPasswordRules"
              v-model="form.confirm_password"
              placeholder="Enter New PIN again"
            />
          </v-col>

          <v-col cols="12" class="mt-5" md="5">
            <fj-base-button
              :loading="loading"
              @click="validateForm"
              block
              :disabled="false"
              >Save Changes</fj-base-button
            >
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import rules from "@/utils/rules";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      rules,
      form: {},
      valid: true,
      loading: false,
      confirmPasswordRules: [
        (v) => !!v || "Password is required",
        (value) =>
          value === this.form.new_password ||
          "The password confirmation does not match.",
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["changePassword"]),
    validateForm() {
      if (this.$refs.form.validate()) {
        this.submit();
      }
    },
    async submit() {
      try {
        this.loading = true;
        await this.changePassword(this.form);
        this.$refs.form.reset();
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../components/style.scss";
</style>
