<template>
  <v-container>
    <v-row class="align-center h-100 top-row">
      <v-col></v-col>
      <v-col cols="12" sm="8" md="7" lg="5" class="white pa-8">
        <auth-title title="Create an account" />
        <v-form
          @submit.prevent="submit()"
          class="mt-6"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" md="6" class="first-name">
              <fj-base-input
                :rules="[private_rules.firstName]"
                v-model="first_name"
                label-inside="First Name"
              />
            </v-col>
            <v-col cols="12" md="6" class="last-name">
              <fj-base-input
                :rules="[private_rules.lastName]"
                v-model="last_name"
                label-inside="Surname"
              />
            </v-col>
          </v-row>
          <fj-base-input
            :rules="[rules.phoneNumber]"
            v-model="phone_no"
            label-inside="Phone Number"
            type="tel"
            :maxlength="11"
          />
          <fj-base-input
            :rules="[rules.email]"
            v-model="email"
            label-inside="Email Address"
            type="email"
          />
          <fj-base-input
            id="ref_code"
            :error-messages="errorMessage"
            :success-messages="null"
            :maxlength="11"
            type="tel"
            :messages="referralName ? referralName : 'Referrals phone number'"
            :loading="referralSearching"
            v-model="referral_code"
            label-inside="Referal Code (Optional)"
          />
          <fj-base-input
            :rules="[private_rules.pin]"
            v-model="pin"
            label-inside="Enter PIN"
            :maxlength="4"
          />
          <fj-base-input
            :rules="[private_rules.confirmPin]"
            v-model="confirm_pin"
            label-inside="Confirm PIN"
            :maxlength="4"
          />
          <v-checkbox
            :rules="[rules.required]"
            class="mt-0 align-start"
            color="dark"
            v-model="checked"
          >
            <template v-slot:label>
              <p class="mb-0 dark--text">
                I have read and agree to the Fajo Microfinance
                <span @click="showDialog" :showDialog="dialog" class="tc-link">
                  terms and conditions
                </span>
              </p>
            </template>
          </v-checkbox>
          <fj-base-button
            :disabled="!valid"
            :loading="loading"
            @click="submit()"
            block
          >
            Create account
          </fj-base-button>
        </v-form>
        <div class="text-center mt-6">
          <router-link to="/login" class="text-center dark--text d-block">
            Already have an account? <u>Login here</u>
          </router-link>
        </div>
      </v-col>
      <v-col></v-col>
    </v-row>

    <div class="text-center mb-4">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="text-h5 dark lighten-2">
            <div>
              <v-btn icon dark @click="closeDialog">
                <v-icon class="close-icon">mdi-close</v-icon>
              </v-btn>
              <span class="text-light">
                Terms & Conditions, and Privacy Policy
              </span>
            </div>
          </v-card-title>

          <v-card-text>
            <TermsCondition />
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import AuthTitle from "../components/AuthTitle.vue";
import TermsCondition from "../../profile/components/T&C.vue";

// ============= IMPORT SERVICES ======
import { mapActions } from "vuex";
import { AuthService } from "@/services";

// ============= IMPORT TEXT FIELDS RULES =======//
import rules from "@/utils/rules";

export default {
  components: {
    AuthTitle,
    TermsCondition
  },
  data: () => ({
    rules,
    errorMessage: "",
    referralSearching: false,
    referral: null,
    first_name: "",
    last_name: "",
    phone_no: "",
    email: "",
    loading: false,
    referral_code: null,
    pin: "",
    confirm_pin: "",
    referralName: null,
    checked: false,
    dialog: false,
    valid: false,
    private_rules: {
      firstName: (value) => !!value || "Please enter First Name",
      lastName: (value) => !!value || "Please enter Surname",
      pin: (value) => !!value || "Please enter Pin",
      confirmPin: (value) => !!value || "Please Confirm Pin",
    },
  }),

  mounted() {
    if (this.$route.query.ref_code) {
      const refCodeInput = document.querySelector("input#ref_code");
      refCodeInput.focus({ preventScroll: true });
      this.referral_code = this.$route.query.ref_code;
      refCodeInput.blur();
    }
  },

  methods: {
    ...mapActions("auth", ["register"]),

    // REGISTER
    async submit() {
      this.loading = true;
      await this.$refs.form.validate();
      if (!this.valid) {
        this.loading = false;
        return;
      }
      let form = {
        first_name: this.first_name,
        last_name: this.last_name,
        phone_no: this.phone_no,
        email: this.email ? this.email : null,
        referral_code: this.referral_code ? this.referral_code : null,
        pin: this.pin,
        confirm_pin: this.confirm_pin,
      };
      try {
        await this.register(form);
      } catch (error) {
        this.loading = false;
      }
    },

    async verifyReferral() {
      try {
        this.referralSearching = true;
        const { data } = await AuthService.verifyReferral(this.referral_code);
        if (data.payload) {
          let { first_name, last_name } = data.payload;
          this.referralName = first_name + " " + last_name;
        } else {
          this.referralName = "Invalid referral code";
        }
        this.referralSearching = false;
      } catch (error) {
        this.referralSearching = false;
        this.errorMessage = error.error[0].message;
      }
    },

    showDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.checked = false;
    },
  },

  watch: {
    referral_code: function (value) {
      if (value) {
        if (value.length === 11) {
          this.verifyReferral();
        } else {
          this.referralName = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 95vh;
  // overflow: auto;

  a {
    text-decoration: none;
  }

  .top-row {
    min-height: 95vh;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .tc-link {
    color: #f56430;
    text-decoration: underline;
  }

  @media only screen and (max-width: 950px) {
    .first-name {
      padding-bottom: 0;
    }
    .last-name {
      padding-top: 0;
    }
  }
}
.text-light {
  color: #fff;
  font-size: 0.8rem;
}
</style>
