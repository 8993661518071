<template>
  <v-container>
    <v-row class="align-center h-100">
      <v-col></v-col>
      <v-col cols="12" md="5" class="pa-8 white">
        <auth-title title="Reset PIN"></auth-title>
        <div class="mb-6">
          <p>Please provide a PIN that’s easy to remember for you</p>
        </div>
        <fj-base-input
          :rules="[rules.required]"
          v-model="otp"
          label-inside="Enter OTP"
        />
        <fj-base-input
          :rules="[private_rules.pin]"
          v-model="pin"
          label-inside="New PIN"
          password
          :maxlength="4"
        />
        <fj-base-input
          :rules="[private_rules.confirmPin]"
          v-model="Cpin"
          label-inside="Confirm New PIN"
          password
          :maxlength="4"
        />
        <fj-base-button @click="submit()" :loading="loading" block>
          Reset PIN
        </fj-base-button>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthTitle from "../components/AuthTitle.vue";
import { mapActions } from "vuex";
import rules from "@/utils/rules";

export default {
  components: {
    AuthTitle,
  },
  data: () => ({
    loading: false,
    username: "",
    otp: "",
    pin: "",
    Cpin: "",
    rules,
    private_rules: {
      pin: (value) => !!value || "Please enter Pin",
      confirmPin: (value) => !!value || "Please Confirm Pin",
    },
  }),
  methods: {
    ...mapActions("auth", ["resetPassword"]),
    async submit() {
      try {
        this.loading = true;
        let form = {
          username: localStorage.getItem("username"),
          otp: this.otp,
          password: this.pin,
          confirm_password: this.Cpin,
        };
        await this.resetPassword(form);
        this.$store.dispatch("snackbar/openSnackbar", {
          text: "Pin reset successful, you proceed to login",
        });
        this.loading = false;
        setTimeout(() => {
          this.$router.push("/login");
        }, 500);
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  overflow: auto;
}
</style>
