<template>
  <div class="mt-12">
    <v-row v-if="projectsaveData">
      <v-col cols="12" md="4">
        <section
          class="white py-10"
          :class="!projectsaveData.active ? 'section-padding' : ''"
        >
          <div class="text-center">
            <h1 class="purple--text">
              ₦{{ displayBalance }}
            </h1>
            <div v-if="projectsaveData.active && projectsaveData.is_autosave">
              <small>Start Date</small>
              <br />
              <small class="text-bold">
                {{ formatDate(projectsaveData.startDate) }}
              </small>
            </div>
          </div>

          <div
            v-if="projectsaveData.active == true"
            class="d-flex justify-space-between mt-4 px-9"
          >
            <div class="text-center">
              <div v-if="projectsaveData.is_autosave">
                <small>{{ projectsaveData.frequency }} Saving</small>
                <h5>₦{{ formatAsMoney(projectsaveData.sub_amount) }}</h5>
              </div>
              <div v-else>
                <small>Start Date</small>
                <h5>{{ formatDate(projectsaveData.startDate) }}</h5>
              </div>
            </div>

            <div class="text-center">
              <small>End Date</small>
              <h5>{{ formatDate(projectsaveData.endDate) }}</h5>
            </div>
          </div>
        </section>

        <fj-base-button
          v-if="projectsaveData.active || projectsaveData.amount > 0"
          @click="moveToWalletTopup"
          block
          class="mt-4"
        >
          TopUp Wallet
        </fj-base-button>

        <fj-base-button
          v-if="!projectsaveData.active"
          @click="startContribution"
          block
          class="mt-4"
        >
          Start Saving
        </fj-base-button>

        <fj-base-button
          v-else-if="
            projectsaveData.active &&
            projectsaveData.status == 'in_progress'
          "
          @click="pause()"
          :loading="loading"
          block
          class="mt-4"
        >
          Pause Autosave
        </fj-base-button>

        <fj-base-button
          v-else-if="
            projectsaveData.active &&
            projectsaveData.status == 'paused' &&
            projectsaveData.is_autosave
          "
          @click="restart()"
          :loading="loading"
          block
          class="mt-4"
        >
          Resume Autosave
        </fj-base-button>

        <fj-base-button
          v-if="!projectsaveData.active && projectsaveData.amount == 0"
          block
          outlined
          class="mt-4"
        >
          No active savings
        </fj-base-button>

        <fj-base-button
          v-if="projectsaveData.active"
          block
          outlined
          class="mt-4"
        >
          Withdrawal in {{ projectsaveData.noOfDays }} days
        </fj-base-button>

        <fj-base-button
          v-if="!projectsaveData.active && projectsaveData.amount > 0"
          @click="moveToWithdrawal"
          block
          outlined
          class="mt-4"
        >
          Withdraw Funds
        </fj-base-button>

        <fj-base-button
          v-if="projectsaveData.active == false && projectsaveData.amount > 0"
          @click="moveToTransferFunds"
          block
          outlined
          class="mt-4"
          >Transfer funds
        </fj-base-button>
      </v-col>
      <v-col cols="12" md="4">
        <fj-product-description
          text="Save daily, weekly or monthly with 
          Lock period of 120days. 12% ROI per annum. Interest paid daily."
          color="purple"
          :icon="require('@/assets/images/svg/product-thumb-up.svg')"
        />

        <div class="mt-5">
          <div class="contact-card pa-5" :style="{ height: '100px' }">
            <small class="d-block title">Contact Support</small>
            <small class="d-block sub">Phone: 09031341125</small>
            <small class="d-block sub">
              Email:
              <a href="mailto: support@fajomonie.com">
                support@fajomonie.com
              </a>
            </small>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <history></history>
      </v-col>
    </v-row>
    <fj-page-loader v-else />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import history from "@/components/history/history.vue";

export default {
  data() {
    return {
      loading: false,
      activityLogs: [],
    };
  },

  components: {
    history,
  },
  async mounted() {
    await this.saveModule("PROJECTSAVE");
    await this.getProjectsave();
  },
  computed: {
    ...mapState({
      projectsaveData: "Projectsave",
      userDetails: "userDetails"
    }),
    minWithdrawalAmount() {
      return this.projectsaveData?.walletDetails?.min_withdrawal_amount || 0;
    },
    displayBalance() {
      if (this.projectsaveData?.amount > 0) {
        return this.formatAsMoney(this.projectsaveData.amount)
      }
      return '0.00';
    },
  },
  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions("history", ["saveModule"]),
    ...mapActions("projectsave", [
      "getProjectsave",
      "pauseProjectsave",
      "restartProjectsave",
    ]),

    startContribution() {
      this.$router.push({ name: "Prs-Contribution" });
    },

    moveToWithdrawal() {
      if (this.minWithdrawalAmount > this.projectsaveData.amount) {
        this.$store.dispatch("snackbar/openSnackbar", {
          text: `Minimum withdrawal amount is ₦${this.minWithdrawalAmount}`,
        });
      }
      else {
        this.$router.push({ name: "Prs-Withdrawal" });
      }
    },

    moveToTransferFunds() {
      this.$router.push({ name: 'Prs-Transfer-Fund' });
    },

    moveToWalletTopup() {
      this.$store.dispatch('wallet/setWalletDetails', 'PROJECTSAVE');
      this.$router.push({ name: "Prs-Topup" });
    },

    async pause() {
      try {
        this.loading = true;
        await this.pauseProjectsave({
          sub_id: Number(this.projectsaveData.subid),
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async restart() {
      try {
        this.loading = true;
        await this.restartProjectsave({
          sub_id: Number(this.projectsaveData.subid),
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-icon {
  padding: 9px 12px;
  border-radius: 30px;
  color: #fff;
  font-size: 17px;
  //   background: var(--primary);
}

.history {
  height: 72vh;
  overflow: auto;

  @media screen and (max-width: 768px) {
    height: 50vh;
  }
}

.purple--text {
  font-size: 2rem;
}

.section-padding {
  .text-center {
    display: flex;
    justify-content: center;
  }
}

.contact-card {
  width: 100%;
  position: relative;
  background-color: #fff;

  .title {
    font-size: 1rem;
  }
}
</style>
