<template>
  <div class="profile-views">
    <div class="white py-8 px-6 mt-10">
      <div v-if="!loading">
        <p><b>Account Details</b></p>
        <v-form ref="form" v-model="valid">
          <v-row class="mt-3">
            <v-col cols="12" md="6" class="py-0">
              <small>Select bank</small>
              <v-autocomplete
                :items="bankList"
                label="Select bank name"
                solo
                flat
                item-text="name"
                item-value="code"
                outlined
                hide-details="auto"
                :disabled="fetchingBanks"
                :loading="fetchingBanks"
                :rules="[rules.required]"
                @change="resolveAccount"
                v-model="form.bank_code"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <fj-base-input
                label-outside="Account Number"
                placeholder="Enter account Number"
                type="number"
                :rules="[rules.required, rules.accountNumber]"
                v-model="form.account_number"
                @input="resolveAccount"
              />
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <fj-base-input
                label-outside="Account Name"
                placeholder=""
                :value="form.account_name"
                @input="form.account_name = $event"
                :loading="resolving"
                readonly
              />
              <div
                v-if="resolving || resolveError"
                style="margin-top: -15px"
              >
                <small class="primary--text">
                  {{resolveAccountMessage}}
                </small>
              </div>
            </v-col>
            <v-col v-if="isUpdate" cols="12" md="6" class="py-0">
              <fj-base-input
                label-outside="Enter Pin"
                placeholder="Your Pin"
                :rules="[rules.required]"
                v-model="form.pin"
                maxLength="4"
              />
            </v-col>

            <v-col align-self="center" cols="12" md="6">
              <fj-base-button
                block
                :disabled="resolving"
                :loading="loading"
                @click="validateForm"
              >
                Save Changes
              </fj-base-button>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <fj-page-loader v-else />
    </div>
  </div>
</template>

<script>
import rules from "@/utils/rules";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      rules,
      valid: true,
      bankList: [],
      form: {},
      edit: false,
      fetchingBanks: false,
      resolving: false,
      resolveError: false,
      loading: false,
    };
  },

  async created() {
    this.fetchBanks();
    if (!this.bankAccount) {
      this.getBankAccount();
    } else {
      this.form = { ...this.bankAccount };
    }
  },

  computed: {
    ...mapState("user", {
      bankAccount: (state) => state.bank
    }),
    isUpdate() {
      return !!this.bankAccount;
    },
    resolveAccountMessage() {
      return this.resolving 
        ? 'Verifying account name...'
        : this.resolveError
        ? 'Unable to verify bank account'
        : ''
    },
  },

  methods: {
    ...mapActions("user", [
      "fetchBankLists",
      "resolveAccountName",
      "addBankAccount",
      "fetchBankAcct",
      "updateBankAccount",
    ]),
    validateForm() {
      if (this.$refs.form.validate() && this.form.account_name) {
        this.submit();
      }
    },
    async fetchBanks() {
      this.fetchingBanks = true;
      try {
        let { banks } = await this.fetchBankLists();
        this.bankList = banks;
        this.fetchingBanks = false;
      } catch (error) {
       this.fetchingBanks = false; 
      }
    },
    async getBankAccount() {
      // this.loading = true;
      try {
        await this.fetchBankAcct();
        this.form = { ...this.bankAccount };
        // this.loading = false;
      } catch (error) {
        // this.loading = false;
      }
    },
    async resolveAccount() {
      if (
        this.form.account_number?.length !== 10 
        || !this.form.bank_code
      ) {
        return;
      }
      this.form.account_name = "";
      try {
        this.resolving = true;
        this.resolveError = false;
        let { payload } = await this.resolveAccountName(this.form);
        this.form.account_name = payload.message;
        this.resolving = false;
      } catch (err) {
        this.resolving = false;
        this.resolveError = true;
      }
    },
    async submit() {
      try {
        this.loading = true;
        let bank = this.bankList.find((elm) => elm.code == this.form.bank_code);
        bank ? (this.form.bank_name = bank.name) : "";

        if (this.isUpdate) {
          await this.updateBankAccount(this.form);
        } else {
          await this.addBankAccount(this.form);
          // this.edit = true;
          // this.$router.push({ name: "Dashboard" });
        }
        await this.getBankAccount();
        this.loading = false;
      } catch (err) {
        this.loading = false;
      } finally {
        // this.fetchBankAcct();
        // this.$store.dispatch("user/fetchUser");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../components/style.scss";
</style>
