<template>
  <div>
    <v-form v-model="valid">
      <v-container>
        <small>Gender</small>
        <v-select
          :items="gender"
          label="Gender"
          solo
          flat
          item-text="text"
          item-value="value"
          outlined
          hide-details="auto"
          class="mb-4"
          v-model="form.gender"
          :rules="[rules.required]"
        ></v-select>

        <small>Date of Birth</small>
        <input type="date" class="dob-input mb-3" v-model="form.dob" />

        <fj-base-input
          labelOutside="Residential Address"
          :value="form.address"
          placeholder="Address"
          @input="form.address = $event"
          :rules="[rules.required]"
        />

        <div class="occupation d-flex pa-2 align-center my-5">
          <div
            :class="{ 'primary white--text': form.occupation_type == 'trader' }"
            class="py-3"
            @click="form.occupation_type = 'trader'"
          >
            Trader
          </div>
          <div
            :class="{
              'primary white--text': form.occupation_type == 'salary_earner',
            }"
            class="py-3"
            @click="form.occupation_type = 'salary_earner'"
          >
            Salary Earner
          </div>
        </div>

        <fj-base-input
          labelOutside="Next of Kin Full Name"
          placeholder="Enter Fullname"
          :value="form.next_of_kin_full_name"
          @input="form.next_of_kin_full_name = $event"
          :rules="[rules.required]"
        />
        <fj-base-input
          labelOutside="Relationship"
          placeholder="Relationship"
          :value="form.next_of_relationship"
          @input="form.next_of_relationship = $event"
          :rules="[rules.required]"
        />
        <fj-base-input
          labelOutside="Phone Number"
          type="tel"
          :maxlength="11"
          :rules="[rules.required]"
          placeholder="Enter phone number"
          v-model="form.next_of_kin_phone"
        />

        <div v-if="!loading">
          <label for=""><small>Security Question</small></label>
          <v-select
            v-if="!form.custom_question"
            :items="question"
            label="Pick a question"
            solo
            flat
            item-text="question"
            item-value="id"
            outlined
            hide-details="auto"
            class="mb-4"
            :rules="[rules.required]"
            v-model="form.question_id"
          ></v-select>
          <fj-base-input
            v-if="form.custom_question"
            label-outside="Custom Security Question"
            v-model="form.question"
            placeholder="Enter Question"
            :rules="[rules.required]"
          />
          <v-checkbox
            class="mt-0 align-start"
            color="dark"
            type="tel"
            v-model="form.custom_question"
          >
            <template v-slot:label>
              <p class="mb-0 dark--text">
                <small>Enter Custom Security Question</small>
              </p>
            </template>
          </v-checkbox>
          <fj-base-input
            label-outside="Security Question Answer"
            password
            v-model="form.answer"
            placeholder="*******"
            :rules="[rules.required]"
          />
        </div>
        <fj-base-button
          block
          @click="submit"
          :loading="loading"
        >
          Save Changes
        </fj-base-button>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import rules from "@/utils/rules";

export default {
  data() {
    return {
      rules,
      valid: false,
      question: [],
      loading: false,
      gender: [
        { text: "Male", value: "male" },
        { text: "Female", value: "female" },
      ],
      form: { occupation_type: "trader" },
    };
  },
  computed: {
    ...mapState("user", ["securityQuestions",]),
  },
  methods: {
    ...mapActions("user", [
      "updateProfile",
      "getSecurityQuestion",
      "saveSecurityQuestionRequest",
    ]),
    async submit() {
      try {
        this.loading = true;
        if (await this.validateForm()) {
          await this.saveSecurityQuestion();
          await this.updateProfile(this.form);

          this.$emit("closeModal");
          this.$store.dispatch("snackbar/openSnackbar", {
            text: "KYC completed",
          });
        } else {
          this.$store.dispatch("snackbar/openSnackbar", {
            text: "Please complete the KYC form",
          });
        }
      } catch (err) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async validateForm() {
      let valid = true;
      const keys = [
        "gender",
        "dob",
        "address",
        "occupation_type",
        "next_of_kin_full_name",
        "next_of_relationship",
        "next_of_kin_phone",
        "answer",
      ];
      keys.forEach((key) => {
        if (!this.form[key]) {
          valid = false;
        }
      });

      if (!this.form.question && !this.form.question_id) valid = false;

      return valid;
    },
    async fetchQuestion() {
      try {
        this.loading = true;
        await this.getSecurityQuestion();
        this.loading = false;
        this.question = this.securityQuestions;
      } catch (err) {
        this.loading = false;
      }
    },
    async saveSecurityQuestion() {
      try {
        this.loading = true;
        if (this.form.custom_question) this.form.question_type = "custom";
        else this.form.question_type = "system";
        await this.saveSecurityQuestionRequest(this.form);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchQuestion();
  },
};
</script>

<style lang="scss" scoped>
.occupation {
  background: #ededed;
  div {
    width: 50%;
    text-align: center;
  }
}
.dob-input {
  width: 100%;
  border: 1px solid #000;
  padding: 15px 10px;
}
</style>
