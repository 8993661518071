<template>
  <v-container>
    <v-row class="align-center h-100 top-row">
      <v-col></v-col>
      <v-col cols="12" md="5" class="white pa-8">
        <auth-title title="Update Phone Number" />
        <div class="mb-6">
          <p>
            Please provide a working phone number that can receive text messages
            and phone calls
          </p>
        </div>
        <fj-base-input
          v-model="phone"
          label-inside="Enter Phone number"
          :rules="[rules.phoneNumber]"
          type="tel"
          :maxlength="11"
        />
        <fj-base-button :loading="loading" @click="submit" block>
          Request OTP
        </fj-base-button>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthTitle from "../components/AuthTitle.vue";
// ============= IMPORT SERVICES ======
import { mapActions } from "vuex";

// ============= IMPORT TEXT FIELDS RULES =======//
import rules from "@/utils/rules";

export default {
  components: {
    AuthTitle,
  },
  data: () => ({
    rules,
    loading: false,
    phone: "",
  }),
  methods: {
    ...mapActions("auth", ["changePhoneNumber"]),

    async submit() {
      try {
        this.loading = true;
        let user = JSON.parse(localStorage.getItem("RegisteredUser"));
        await this.changePhoneNumber({
          new_phone_no: this.phone,
          otp: user.update_phone_otp,
          auth_id: user.auth.id,
        });

        this.$store.dispatch("snackbar/openSnackbar", {
          text: `Phone number changed successfully`,
        });
        this.$router.push({
          name: "Verify Account",
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
  overflow: auto;

  .top-row {
    min-height: 90vh;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
