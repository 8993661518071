<template>
  <div class="mt-7">
    <div class="card-wrapper d-flex flex-wrap">
      <div
        v-for="(item, index) in filteredCards"
        :key="index"
        :class="item.class"
        class="sections"
      >
        <router-link :to="'/portfolio/' + item.link">
          <div class="d-flex flex-column d-cards pa-5">
            <div class="mb-2">
              <v-img :src="item.cardIcon" class="card-icon"></v-img>
            </div>
            <h4 class="mb-2">{{ item.title }}</h4>
            <small class="dark--text description">{{ item.description }} </small>

            <h3 class="mt-auto">₦{{ item.amount }}</h3>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { WalletEnum } from "@/enums";
import { formatAsMoney } from "@/utils/helpers";
export default {
  data() {
    return {
      showInterest: false,
      cardData: [
        {
          cardIcon: require("@/assets/images/svg/dash-flex.svg"),
          amount: "0.00",
          title: "Ajo (Thrift Flex)",
          link: "thrift-flex",
          description: "Save as little as N1,000 over 30 days",
          wallet_type: WalletEnum.AJO,
          class: 'ajo',
          show: true,
        },
        {
          cardIcon: require("@/assets/images/svg/thumb-up.svg"),
          amount: "0.00",
          title: "Project Save",
          link: "project-save",
          description:
            "Save daily, weekly or monthly to achieve your short term goals",
          wallet_type: WalletEnum.PROJECTSAVE,
          class: 'prs',
          show: true,
        },
        {
          cardIcon: require("@/assets/images/svg/power.svg"),
          amount: "0.00",
          title: "Power Save",
          link: "power-save",
          description:
            "Save daily, weekly or monthly to achieve your long term goals",
          wallet_type: WalletEnum.POWERSAVE,
          class: 'pws',
          show: true,
        },
        {
          cardIcon: require("@/assets/images/svg/power.svg"),
          amount: "0.00",
          title: "Super Save",
          link: "super-save",
          description: "Welcome to the League of the extra-ordinary savers.",
          wallet_type: WalletEnum.SUPERSAVE,
          class: 'pws',
          show: false,
        },
        {
          cardIcon: require("@/assets/images/svg/percent-port.svg"),
          amount: "0.00",
          title: "Emergency Funds",
          link: "emergency-funds",
          description:
            "You don't have to liquidate your savings. Get an emergency fund",
          wallet_type: "LOAN",
          class: 'emergency-funds',
          show: true,
        },
      ],
      reward: {
        cardIcon: require("@/assets/images/svg/reward.svg"),
        amount: "0.00",
        title: "Rewards",
        link: "rewards",
        description:
          "You can withdraw your reward directly into your bank account",
      },
      interest: {
        cardIcon: require("@/assets/images/svg/interest.svg"),
        amount: "0.00",
        title: "My Interest",
        link: "interest",
        description: "Interests are paid daily. My Interest as at today is",
        wallet_type: WalletEnum.INTEREST,
      },
    };
  },
  computed: {
    ...mapState(["userDetails"]),
    ...mapState("reward", ["commission"]),
    filteredCards() {
      return this.cardData.filter(item => item.show)
    },
  },
  watch: {
    userDetails() {
      this.asignWalletAmounts();
    },

    commission(val) {
      this.reward.amount = val.amount;
    },
  },
  async mounted() {
    let routePath = this.$route.path.split("/");
    if (routePath[1] === "portfolio") this.showInterest = true;
    else this.showInterest = false;
    await this.asignWalletAmounts();
  },

  methods: {
    formatAsMoney,
    ...mapActions(["getSavedWallet"]),
    ...mapActions("reward", ["fetchCommission"]),
    async getCommission() {
      try {
        this.loading = true;
        await this.fetchCommission();
      } catch (err) {
        this.loading = false;
      }
    },

    async asignWalletAmounts() {
      this.cardData.forEach(async (card) => {
        const wallet = await this.getSavedWallet(card.wallet_type);
        if (wallet?.balance > 0) {
          card.amount = this.formatAsMoney(wallet.balance);
        }

        if (wallet?.wallet_type == 'SUPERSAVE') {
          card.show = true;   
        }
      });
      let loan = this.cardData.find((elm) => elm.wallet_type == "LOAN");
      if (
        this.userDetails.amountBorrowed &&
        this.userDetails.amountBorrowed > 0
      ) {
        loan.amount = this.formatAsMoney(this.userDetails.amountBorrowed);
      } else if (this.userDetails.maxLoan && this.userDetails.maxLoan > 0) {
        loan.amount = this.formatAsMoney(this.userDetails.maxLoan);
      }
      if (this.showInterest) {
        const interestWallet = await this.getSavedWallet(
          this.interest.wallet_type
        );
        if (interestWallet && interestWallet.balance > 0) {
          this.interest.amount = this.formatAsMoney(interestWallet.balance);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  // gap: 17px;
  background-color: #FFF6F2;
  padding: 1.5rem 1rem;
  border-radius: 17px;
  justify-content: space-between;

  .sections {
    width: 98%;
    margin-bottom: 1rem;

    &.ajo {
      h4,
      h3 {
        color: var(--secondary);
      }

      .description {
        background-color: rgb(0, 75, 35, 0.35);
      }
    }
    &.prs {
      h4,
      h3 {
        color: #6d205c;
      }

      .description {
        background-color: rgb(209, 21, 75, 0.17);
      }
    }
    &.pws {
      h4,
      h3 {
        color: #20316d;
      }

      .description {
        background-color: rgb(158, 165, 192, 0.42);
      }
    }
    &.emergency-funds {
      h4,
      h3 {
        color: #000;
      }
    }

    small {
      font-size: 14px;
    }

    @media screen and (min-width: 768px) {
      width: 47%;
    }

    @media only screen and (min-width: 1200px) {
      width: 18%;
    }
  }
  a {
    text-decoration: none;
  }
}
.d-cards {
  height: 250px;
  background: #fff;
  box-shadow: 0 4px 15px rgb(0, 0, 0, 0.3);
  border-radius: 10px;

  .card-icon {
    width: 22px;
  }

  .description {
    padding: 0.35rem;
  }

  small {
    font-size: 11.5px;
  }
}

.refer-card {
  height: 250px;
  background: #000;
  position: relative;

  .card-icon {
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 30px;
  }
}

.interest-card {
  height: 250px;
  background: #fff;
  position: relative;

  .interest-amount,
  .interest-title {
    color: #20316d;
  }

  .card-icon {
    width: 12%;
  }
}
</style>
