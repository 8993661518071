<template>
  <div>
    <div class="white px-6 py-8 mt-10">
      <p class="primary edit-info pa-5">
        To update your Personal details please send an email to
         <a href="mailto: support@fajomonie.com"> support@fajomonie.com </a>
      </p>
      <p><b>Personal Details</b></p>
      <v-row class="mt-3">
        <v-col cols="12" md="5" class="py-0">
          <small>First Name</small>
          <fj-base-input
            :value="form.first_name"
            placeholder="First Name"
            @input="form.first_name = $event"
          />
        </v-col>
        <v-col cols="12" md="5" class="py-0">
          <small>Last Name</small>
          <fj-base-input
            :value="form.last_name"
            placeholder="Last Name"
            @input="form.last_name = $event"
          />
        </v-col>
        <v-col cols="12" md="5" class="py-0">
          <small>Phone Number</small>
          <fj-base-input
            :value="form.phone_no"
            placeholder="Enter Phone Number"
            @input="form.phone_no = $event"
          />
        </v-col>
        <v-col cols="12" md="5" class="py-0">
          <small>Email Address</small>
          <fj-base-input
            :value="form.email"
            placeholder="Enter Email"
            v-model="form.email"
            @input="form.email = $event"
          />
        </v-col>
        <v-col cols="12" md="5" class="py-0">
          <small>Date of Birth</small>
          <input type="date" class="dob-input mb-3" @input="assignDob" />
        </v-col>
        <v-col cols="12" md="5" class="py-0 mb-3">
          <small>Gender</small>
          <v-select
            :items="gender"
            label="Gender"
            solo
            flat
            item-text="text"
            item-value="value"
            outlined
            hide-details="auto"
            class="mb-4"
            v-model="form.gender"
          ></v-select>
        </v-col>
        <v-col cols="12" md="10" class="py-0">
          <small>Address</small>
          <fj-base-input
            :value="form.address"
            placeholder="Address"
            @input="form.address = $event"
          />
        </v-col>

        <v-col cols="12" md="5" class="mt-15">
          <fj-base-button block :loading="loading" disabled
            >Save Changes</fj-base-button
          >
        </v-col>
      </v-row>

      <!-- <p><b>Next of Kin</b></p>
      <fj-base-input placeholder="Enter Fullname" />
      <fj-base-input placeholder="Relationship" />
      <fj-base-input placeholder="Enter phone number" /> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      gender: [
        { text: "Male", value: "male" },
        { text: "Female", value: "female" },
      ],
      form: { first_name: "", dob: "2017-07-04" },
    };
  },
  computed: {
    ...mapState(["userDetails"]),
  },
  methods: {
    ...mapActions("user", ["updateProfile"]),
    async submit() {
      try {
        this.loading = true;
        let data = await this.updateProfile(this.form);
        console.log(data);
      } catch (err) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    assignDob($event) {
      this.form.dob = $event.target.value;
    },
  },
  mounted() {
    this.form = { ...this.userDetails.customer };
    let dobField = document.getElementsByClassName("dob-input")[0];
    if (this.userDetails.customer.dob) {
      var dob = this.form.dob.split("T");
      dobField.value = dob[0];
    }
  },
};
</script>

<style lang="scss" scoped>
.dob-input {
  width: 100%;
  border: 1px solid #000;
  padding: 15px 10px;
}
.edit-info {
  padding: 6px;
  color: white;

  a {
    color: #000;
  }
}
</style>
