<template>
  <div class="mt-12">
    <v-row v-if="interestWallet && interestDetails">
      <v-col cols="12" md="4">
        <section class="white py-10">
          <div class="text-center">
            <h1 class="powersave-color__text">
              ₦{{
                interestWallet.balance > 0
                  ? formatAsMoney(interestWallet.balance)
                  : "0.00"
              }}
            </h1>
            <small>Accumulated Interest</small>
          </div>
        </section>
        <fj-base-button @click="moveToWithdrawal" block class="mt-4">
          Withdraw Funds
        </fj-base-button>
      </v-col>
      <v-col cols="12" md="4">
        <fj-product-description
          text="Track all interests you’ve accumulated across all of your Fajo savings."
          color="powersave-color"
          :icon="require('@/assets/images/svg/stop-circle.svg')"
        />
      </v-col>
      <v-col cols="12" md="4">
        <history :defaultTabs="tabs" defaultEntity="interest_history" />
      </v-col>
    </v-row>
    <fj-page-loader v-else />

    <!-- dialog to inform on maximum before withdrawal -->
    <v-row justify="center" v-if="interestDetails">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5 text-centre card-title">
            NOTE
          </v-card-title>
          <v-card-text>
            Due to ongoing savings, you can not withdraw more than 80% of your
            interest wallet. <br />
            Maximum: NGN {{ formatAsMoney(interestDetails.chargeableAmount) }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="primary darken-1" text @click="continueToWithdrawal">
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import history from "@/components/history/history.vue";

export default {
  data() {
    return {
      page: 1,
      sheet: false,
      loading: false,
      dialog: false,
      interestDetails: null,
      tabs: [
        {
          title: "History",
          entity: "interest_history",
        },
        {
          title: "Withdrawals",
          entity: "withdrawal",
        },
      ]
    };
  },
  async mounted() {
    await this.saveModule("INTEREST");
    await this.fetchUser();
    const { payload } = await this.getInterestDetails();
    this.interestDetails = payload;
  },
  components: {
    history,
  },
  computed: {
    ...mapState(["userDetails"]),
    ...mapState("history", ["moduleName"]),

    interestWallet() {
      let wallets = this.userDetails.customer.wallets;
      let IWallet = wallets.find((elm) => elm.wallet_type == "INTEREST");

      return IWallet;
    },
  },
  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions("wallet", ["getInterestDetails"]),
    ...mapActions("history", ["saveModule"]),
    ...mapActions("user", ["fetchUser"]),

    moveToWithdrawal() {
      if (this.interestDetails.savingsActive) {
        this.dialog = true;
      } else {
        this.continueToWithdrawal();
      }
    },

    continueToWithdrawal() {
      this.dialog = false;
      this.$router.push({ name: "Interest Withdrawal" });
    },
  },
};
</script>
<style lang="scss" scoped>
.powersave-color {
  background: #20316d;

  &__text {
    font-size: 2rem;
    color: #20316d;
  }
}

.activity-icon {
  padding: 9px 12px;
  border-radius: 30px;
  color: #fff;
  font-size: 17px;
  //   background: var(--primary);
}

.history {
  height: 72vh;
  overflow: auto;

  @media screen and (max-width: 768px) {
    height: 50vh;
  }
}
</style>
