<template>
  <v-container>
    <h3 class="mb-5 mt-2">Rewards</h3>
    <v-row v-if="commission" class="mt-5">
      <v-col md="4" cols="12">
        <section class="py-10 white">
          <div class="text-center">
            <h1 class="black--text">
              N
              {{
                commission.amount > 0
                  ? formatAsMoney(commission.amount)
                  : "0.00"
              }}
            </h1>
            <small>Bonus Earned</small>
          </div>

          <div class="d-flex justify-space-between mt-4 px-9">
            <div class="text-center">
              <small>No of referrals</small>
              <h5>
                {{ commission.referral_count }}
              </h5>
            </div>

            <div class="text-center">
              <small>Active Referrals</small>
              <h5>{{ commission.active_referral_count }}</h5>
            </div>
          </div>
        </section>
      </v-col>
      <v-col md="4" cols="12">
        <fj-product-description
          text="Refer and Earn"
          subtitle="Enjoy referral  bonus every time  a member register and saves using your referral code"
          color="black"
          :icon="require('@/assets/images/svg/reward.svg')"
        />
        <br />
      </v-col>
      <v-col md="4" cols="12">
        <label for="">Referral Link</label>
        <v-text-field
          readonly
          class="rounded-0 mb-3"
          id="ref-link"
          v-model="referral_link"
          hide-details
          outlined
        >
          <template v-slot:append>
            <v-icon color="#000" @click="copyLink"> mdi-content-copy </v-icon>
          </template>
        </v-text-field>
        <label for="">Referral Code</label>
        <v-text-field
          readonly
          class="rounded-0"
          v-model="referral_code"
          hide-details
          outlined
          id="ref-code"
        >
          <template v-slot:append>
            <v-icon color="#000" @click="copyCode"> mdi-content-copy </v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col>
            <fj-base-button
              block
              class=""
              @click="() => $router.push({ name: 'Rewards Withdrawal' })"
            >
              Withdraw
            </fj-base-button>
          </v-col>
          <v-col cols="12">
            <history />
          </v-col>
        </v-row>
      </v-col>
      <v-col md="8" cols="12">
        <activity-slide />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { formatAsMoney } from "@/utils/helpers";
import ActivitySlide from "./components/ActivitySlide";
import History from "../../components/history/history.vue";

export default {
  data() {
    return {
      sheet: false,
      actionButton: [
        {
          icon: require("@/assets/images/svg/phone-outgoing.svg"),
          text: "Support",
        },
        {
          icon: require("@/assets/images/svg/clock-icon.svg"),
          text: "History",
        },
        {
          icon: require("@/assets/images/svg/corner-up-right.svg"),
          text: "Transfer",
        },
      ],
    };
  },

  computed: {
    ...mapState(["userDetails"]),
    ...mapState("reward", [
      "commission",
      "active_referrals",
      "inactive_referrals",
    ]),

    referral_code() {
      return this.userDetails.customer.phone_no;
    },

    referral_link() {
      return (
        this.$appUrl +
        "/register?ref_code=" +
        this.userDetails.customer.phone_no
      );
    },
  },

  components: {
    ActivitySlide,
    History,
  },

  async mounted() {
    await this.saveModule("COMMISSION");
    await this.getCommission();
    await this.fetchActiveReferrals();
    await this.fetchInactiveReferrals();
  },

  methods: {
    formatAsMoney,
     ...mapActions("history", ["saveModule"]),
    ...mapActions("reward", [
      "fetchActiveReferrals",
      "fetchInactiveReferrals",
      "fetchCommission",
    ]),
    async getCommission() {
      try {
        this.loading = true;
        await this.fetchCommission();
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    copyLink() {
      var copyText = document.getElementById("ref-link");
      /* Select the text field */
      copyText.disabled = false;
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      /* Copy the text inside the text field */
      document.execCommand("copy");
      copyText.disabled = true;

      this.isCopied = true;

      this.$store.dispatch("snackbar/openSnackbar", {
        text: "Referral link copied.",
      });
    },

    copyCode() {
      var copyText = document.getElementById("ref-code");
      /* Select the text field */
      copyText.disabled = false;
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      /* Copy the text inside the text field */
      document.execCommand("copy");
      copyText.disabled = true;

      this.isCopied = true;

      this.$store.dispatch("snackbar/openSnackbar", {
        text: "Referral code copied.",
      });
    },

    shareViaWebShare() {
      navigator.share({
        title: "Hi, Visit Fajo monnie at",
        url: "http://fajomonnie.com/",
        text:
          "And register with my referral code" +
          this.userDetails.customer.phone_no,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
