<template>
  <div class="activity-page w-100">
    <v-row class="activity mb-6">
      <v-col md="6" cols="12" class="">
        <div class="act-slide pa-4">
          <h4 class="mb-2">Active Referrals</h4>
          <div class="" v-if="active && active.length > 0">
            <div
              class="d-flex justify-space-between py-2"
              v-for="(item, index) in active"
              :key="index"
            >
              <small> {{ item.first_name + " " + item.last_name }} </small>

              <small>{{ item.phone_no }} </small>
            </div>
          </div>
          <div
            class="text-center d-flex align-center justify-center"
            style="opacity: 0.4; height: 85%"
            v-else
          >
            <h3 class="mb-10">No Referrals</h3>
          </div>
        </div>
      </v-col>

      <v-col md="6" cols="12" class="">
        <div class="act-slide pa-4">
          <h4 class="mb-2">Inactive Referrals</h4>
          <div class="" v-if="inactive && inactive.length > 0">
            <div
              class="d-flex justify-space-between py-2"
              v-for="(item, index) in inactive"
              :key="index"
            >
              <small> {{index +1}}. {{ item.first_name + " " + item.last_name }} </small>

              <small>{{ item.phone_no }} </small>
            </div>
          </div>
          <div
            class="text-center d-flex align-center justify-center"
            style="opacity: 0.4; height: 85%"
            v-else
          >
            <h3 class="mb-10">No Referrals</h3>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      active: null,
      inactive: null,
    }
  },
  computed: {
    ...mapState("reward", ["active_referrals", "inactive_referrals"]),
  },
  methods: {
    ...mapActions("reward", [
      "fetchActiveReferrals",
      "fetchInactiveReferrals",
      "fetchCommission",
    ]),
  },
  async mounted() {
    await this.fetchActiveReferrals();
    await this.fetchInactiveReferrals()
    this.active = this.active_referrals.rows;
    this.inactive = this.inactive_referrals.rows;
  }
};
</script>

<style lang="scss" scoped>
.activity-page {
  .activity {
    // width: 180%;
    .act-slide {
      background: #fff;
      height: 270px;
      overflow: auto;
      // width: 85%;
    }
  }
}
</style>
