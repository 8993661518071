<template>
  <div class="mt-12">
    <v-row v-if="powersaveData">
      <v-col cols="12" md="4">
        <section
          class="white py-10"
          :class="!powersaveData.active ? 'section-padding' : ''"
        >
          <div class="text-center">
            <h1 class="powersave-color__text">
              ₦{{ displayBalance }}
            </h1>
            <div v-if="powersaveData.active && powersaveData.is_autosave">
              <small>Start Date</small>
              <br />
              <small class="text-bold">
                {{ formatDate(powersaveData.startDate) }}
              </small>
            </div>
          </div>

          <div
            v-if="powersaveData.active"
            class="d-flex justify-space-between mt-4 px-9"
          >
            <div class="text-center">
              <div v-if="powersaveData.is_autosave">
                <small>{{ powersaveData.frequency }} Saving</small>
                <h5>₦{{ formatAsMoney(powersaveData.sub_amount) }}</h5>
              </div>
              <div v-else>
                <small>Start Date</small>
                <h5>{{ formatDate(powersaveData.startDate) }}</h5>
              </div>
            </div>

            <div class="text-center">
              <small>End Date</small>
              <h5>{{formatDate(powersaveData.endDate)}}</h5>
            </div>
          </div>
        </section>

        <fj-base-button
          v-if="powersaveData.active || powersaveData.amount > 0"
          @click="moveToWalletTopup"
          block
          class="mt-4"
        >
          TopUp Wallet
        </fj-base-button>

        <fj-base-button
          v-if="!powersaveData.active"
          @click="startContribution"
          block
          class="mt-4"
        >
          Start Saving
        </fj-base-button>

        <fj-base-button
          v-else-if="
            powersaveData.active &&
            powersaveData.status == 'in_progress'
          "
          @click="pause()"
          :loading="loading"
          block
          class="mt-4"
        >
          Pause Autosave
        </fj-base-button>

        <fj-base-button
          v-else-if="
            powersaveData.active &&
            powersaveData.status == 'paused' &&
            powersaveData.is_autosave
          "
          @click="restart()"
          :loading="loading"
          block
          class="mt-4"
        >
          Resume Autosave
        </fj-base-button>

        <fj-base-button
          v-if="!powersaveData.active && powersaveData.amount == 0"
          block
          outlined
          class="mt-4"
        >
          No active savings
        </fj-base-button>

        <fj-base-button
          v-if="powersaveData.active"
          block
          outlined
          class="mt-4"
        >
          Withdrawal in {{ powersaveData.noOfDays }} days
        </fj-base-button>

        <fj-base-button
          v-if="!powersaveData.active && powersaveData.amount > 0"
          @click="moveToWithdrawal"
          block
          outlined
          class="mt-4"
        >
          Withdraw Funds
        </fj-base-button>
      </v-col>
      <v-col cols="12" md="4">
        <fj-product-description
          text="Save daily, weekly or monthly with lock period of 210days. 20% ROI per annum. Interest paid daily."
          color="powersave-color"
          :icon="require('@/assets/images/svg/thumbs.svg')"
        />

        <div class="mt-5">
          <div class="contact-card pa-5" :style="{ height: '100px' }">
            <small class="d-block title">Contact Support</small>
            <small class="d-block sub">Phone Number: 09031341125</small>
            <small class="d-block sub">
              Email:
              <a href="mailto: support@fajomonie.com">
                support@fajomonie.com
              </a>
            </small>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <history></history>
      </v-col>
    </v-row>
    <fj-page-loader v-else />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import history from "@/components/history/history.vue";

export default {
  data() {
    return {
      loading: false,
      activityLogs: [],
    };
  },
  async mounted() {
    await this.saveModule("POWERSAVE");
    await this.getPowersave();
  },

  components: {
    history,
  },
  computed: {
    ...mapState({
      powersaveData: "Powersave",
      userDetails: "userDetails"
    }),
    minWithdrawalAmount() {
      return this.powersaveData?.walletDetails?.min_withdrawal_amount || 0;
    },
    displayBalance() {
      if (this.powersaveData?.amount > 0) {
        return this.formatAsMoney(this.powersaveData.amount)
      }
      return '0.00';
    },
  },
  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions("history", ["saveModule"]),
    ...mapActions("powersave", [
      "getPowersave",
      "pausePowersave",
      "restartPowersave",
    ]),

    startContribution() {
      this.$router.push({ name: "Pws-Contribution" });
    },

    moveToWalletTopup() {
      this.$store.dispatch('wallet/setWalletDetails', 'POWERSAVE');
      this.$router.push({ name: "Pws-Topup" });
    },

    moveToWithdrawal() {
      if (this.minWithdrawalAmount > this.powersaveData.amount) {
        this.$store.dispatch("snackbar/openSnackbar", {
          text: `Minimum withdrawal amount is ₦${this.minWithdrawalAmount}`,
        });
      } else {
        this.$router.push({ name: "Pws-Withdrawal" });
      }
    },

    async pause() {
      try {
        this.loading = true;
        await this.pausePowersave({
          sub_id: Number(this.powersaveData.subid),
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async restart() {
      try {
        this.loading = true;
        await this.restartPowersave({
          sub_id: Number(this.powersaveData.subid),
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.powersave-color {
  background: #20316d;

  &__text {
    color: #20316d;
    font-size: 2rem;
  }
}

.activity-icon {
  padding: 9px 12px;
  border-radius: 30px;
  color: #fff;
  font-size: 17px;
  // background: var(--primary);
}

.history {
  height: 72vh;
  overflow: auto;

  @media screen and (max-width: 768px) {
    height: 50vh;
  }
}

.section-padding {
  .text-center {
    display: flex;
    justify-content: center;
  }
}

.contact-card {
  width: 100%;
  position: relative;
  background-color: #fff;

  .title {
    font-size: 1rem;
  }
}
</style>
