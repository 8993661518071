<template>
  <v-container class="">
    <v-row class="align-center h-100">
      <v-col></v-col>
      <v-col cols="12" md="5" class="white pa-8">
        <auth-title class="mb-3" :loginPage="true" title="Login to continue" />
        <v-form @submit.prevent="submit()">
          <fj-base-input
            v-model="username"
            label-inside="Enter Phone number"
            type="tel"
          />
          <fj-base-input
            v-model="password"
            label-inside="Your Pin"
            maxLength="4"
            password
          />
          <div class="mt-10 d-flex justify-space-between align-center">
            <v-checkbox
              v-model="form.checked"
              label="Remember Me"
              color="primary"
              value="true"
              class="font-weight-medium"
            ></v-checkbox>
            <router-link to="/forgot-pin" class="mb-0 dark--text"
              >Forgot PIN</router-link
            >
          </div>
          <fj-base-button :loading="loading" type="submit" block>
            Login
          </fj-base-button>
        </v-form>
        <div class="text-center bottom-link">
          <router-link
            to="/register"
            class="text-center dark--text d-block my-15"
            >Not a user? <u>Signup here</u>
          </router-link>
        </div>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
// ========= IMPORT COMPONENT  ======
import AuthTitle from "../components/AuthTitle.vue";

// ============= IMPORT SERVICES ======
import { mapActions } from "vuex";

export default {
  data() {
    return {
      username: "",
      password: "",
      form: {},
      loading: false,
    };
  },
  components: {
    AuthTitle,
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async submit() {
      this.loading = true;
      let form = {
        username: this.username,
        password: this.password,
      };
      try {
        await this.login(form);
      } catch (err) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;

  a {
    text-decoration: none;
  }
}
</style>
