export default {
  required: (value) => !!value || "Required",
  password: (value) => value.length > 7 || "Minimum of 8 characters.",
  phoneNumber: (value) =>
    (value && value.length == 11) || "Invalid Phone number",
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value == null || value == "") return true
    return pattern.test(value) || "Invalid email address.";
  },
  accountNumber: (value) => (value && value.length == 10) || "Account number must be 10 characters"
};
