const wallets = Object.freeze({
  AJO: "AJO",
  PROJECTSAVE: "PROJECTSAVE",
  POWERSAVE: "POWERSAVE",
  SUPERSAVE: "SUPERSAVE",
  INTEREST: "INTEREST",
  COMMISSION: "COMMISSION",
});

export default wallets;
