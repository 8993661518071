<template>
  <v-container>
    <div class="d-flex align-center px-3 mb-8">
      <div class="mr-4 pointer" @click="$router.back()">
        <v-img src="@/assets/images/svg/back-arrow.svg" width="20"></v-img>
      </div>
      <h3 class="mb-0">Rewards</h3>
    </div>
    <v-row v-if="commission" class="justify-md-center">
      <v-col cols="12" md="5">
        <div class="withdrawal pa-5 white">
          <h3 class="mb-4">Withdraw funds</h3>
          <fj-base-input
            placeholder="N20,0000"
            labelOutside="Enter Amount"
            type="number"
            v-model="amount"
            :rules="amountRules"
          />
          <fj-base-input
            placeholder="Enter Your Pin"
            labelOutside="Enter Pin"
            type="number"
            v-model="pin"
            :rules="[rules.required]"
          />
          <small for="">From</small>
          <div class="details active mb-4 from-card">
            <h3 class="mb-0">{{ fullName }} - Fajo Interest</h3>
            <small class="d-block">
              NGN
              {{ formatAsMoney(commission.amount) }}
            </small>
          </div>

          <small for="">To</small>
          <div v-if="bank" class="details mb-4 to-card">
            <h3 class="mb-0">{{ bank.account_name }} - {{ bank.bank_name }}</h3>
            <small>{{ bank.account_number }}</small>
          </div>

          <fj-base-button
            :disabled="!amount || amount < 100 || !pin || pin.length < 4"
            block
            class="mt-5"
            :loading="loading"
            @click="withdraw()"
          >
            Authorize Withdrawal
          </fj-base-button>
        </div>
      </v-col>
    </v-row>
    <fj-page-loader v-else></fj-page-loader>

    <fj-response-modal
      :openModal="openModal"
      :status="status"
      :title="title"
      :message="message"
      callbackUrl="Rewards"
    />
  </v-container>
</template>

<script>
import rules from "@/utils/rules";
import FjResponseModal from "../../../components/modals/FjResponseModal.vue";
import { mapState, mapActions } from "vuex";
import { formatAsMoney } from "@/utils/helpers";
import { WithdrawalService } from "@/services";

export default {
  data() {
    return {
      rules,
      dialog: false,
      amount: "",
      pin: "",
      openModal: false,
      status: "",
      message: "",
      title: "",
      loading: false,
      amountRules: [
        (value) => !!value || "Required",
        (value) => value >= 100 || "Minimum amount is NGN 100",
      ],
    };
  },
  components: {
    FjResponseModal,
  },
  computed: {
    ...mapState(["userDetails"]),
    ...mapState("user", ["bank"]),
    ...mapState("reward", ["commission"]),
    fullName() {
      if (this.userDetails) {
        return this.userDetails.customer.full_name;
      }

      return "";
    },
  },
  async mounted() {
    if (!this.bank) {
      await this.fetchBankAcct();
    }
    await this.fetchCommission();
  },
  methods: {
    formatAsMoney,
    ...mapActions(["getSavedWallet"]),
    ...mapActions("user", ["fetchBankAcct"]),
    ...mapActions("reward", ["fetchCommission"]),

    async withdraw() {
      if (this.amount > this.commission.amount) {
        this.$store.dispatch("snackbar/openSnackbar", {
          text: "Insufficient Balance",
        });
      } else {
        await this.completeWithdrawal();
      }
    },

    async completeWithdrawal() {
      try {
        this.dialog = false;
        this.loading = true;
        let wallet = await this.getSavedWallet("COMMISSION");
        let payload = {
          amount: Number(this.amount),
          pin: this.pin,
          wallet_id: wallet.id,
        };
        await WithdrawalService.withdrawalRequest(payload);
        this.loading = false;
        this.successMessage();
      } catch (error) {
        this.errorMessage();
        this.loading = false;
      }
    },

    async successMessage() {
      this.title = "Request Successful";
      this.message = `Your withdrawal request of ₦${this.amount} from your Fajo Interest will be credited 
      to your bank account within 24hrs.`;
      this.status = "success";
      this.openModal = true;
    },

    async errorMessage() {
      this.title = "Request Failed";
      this.message = `Your withdrawal request of ₦${this.amount} from your Fajo Interest failed.`;
      this.status = "failed";
      this.openModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawal {
  .details {
    width: 100%;
    background: rgba(21, 21, 21, 0.1);
    padding: 14px 24px;
    h3 {
      font-size: 15px;
    }
    small {
      display: block;
      margin-top: 0px;
    }
  }

  .from-card {
    background: linear-gradient(
      0deg,
      rgba(109, 32, 92, 0.1),
      rgba(109, 32, 92, 0.1)
    );
    border: 1px solid rgba(109, 32, 92, 1);
  }
}
</style>
