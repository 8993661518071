<template>
  <div>
    <div class="card-wrapper">
      <div v-for="(item, index) in cardData" :key="index" class="card" :class="item.class">
        <div class="card-background" :style="{ 'background-image': 'url(' + item.cardBackground + ')' }">
          <div class="card-content-wrapper">
            <div class="mr-2">
              <v-img :src="item.cardIcon" class="card-icon"></v-img>
            </div>
            <div class="w-100">
              <div class="card-title-btn-wrapper">
                <span class="card-title">{{ item.title }}</span>
                <div class="card-btn" @click="toggleSaving(index)">
                  <v-img :src="item.cardEditIcon" class="mr-2 card-btn-icon" />
                  <span>
                    {{ item.showBal ? 'Hide Amount' : 'Show Amount' }}
                  </span>
                </div>
              </div>
              <div class="card-amount">
                <p v-if="item.showBal">
                  ₦{{ formatAsMoney(item.amount) }}
                </p>
                <p v-else>******</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-mobile-wrapper">
      <v-carousel v-model="model" class="card-mobile-carousel" :show-arrows="false" hide-delimiters>
        <v-carousel-item v-for="(item, idx) in cardData" :key="idx">
          <div>
            <div class="card-mobile card-mobile-top" :class="item.class">
              <div class="swipe-text">
                <img class="mr-1" :src="require('@/assets/images/svg/dash-card-arrow.svg')" alt="">
                <span>Swipe</span>
              </div>
              <div class="card-mobile-bottom" :style="{ 'background-image': 'url(' + item.cardBackground + ')' }">
                <div class="d-flex card-mobile-content">
                  <div class="mr-2 pt-2">
                    <img :src="item.cardIcon" alt="">
                  </div>
                  <div class="card-mobile-text">
                    <div class="d-flex justify-space-between">
                      <span>{{ item.title }}</span>
                      <div @click="toggleSaving(idx)" class="card-mobile-button">
                        {{ item.showBal ? 'Hide Amount' : 'Show Amount' }}
                      </div>
                    </div>
                    <div v-if="item.showBal" class="card-mobile-amount">
                      ₦{{ formatAsMoney(item.amount) }}
                    </div>
                    <p class="mb-0 card-mobile-amount" v-else>
                      ₦ ******
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import { formatAsMoney } from "@/utils/helpers";
import { WalletEnum } from "../../../enums/index";
import { mapState, mapActions } from "vuex";
const CARD_SETTINGS = 'balanceCardStatus';

export default {
  data() {
    return {
      model: 0,
      cardData: [
        {
          id: 1,
          title: "Total Savings",
          amount: 0,
          showBal: true,
          class: 'total-savings-card',
          cardBackground: require("@/assets/images/svg/dash-card-bg-1.svg"),
          cardIcon: require("@/assets/images/svg/dash-card-icon-1.svg"),
          cardBlock: require("@/assets/images/svg/dash-card-block-1.svg"),
          cardEditIcon: require("@/assets/images/svg/dash-card-edit-icon.svg"),
        },
        {
          id: 2,
          title: "Interest",
          amount: 0,
          showBal: true,
          class: 'interest-card',
          cardBackground: require("@/assets/images/svg/dash-card-bg-2.svg"),
          cardIcon: require("@/assets/images/svg/dash-card-icon-2.svg"),
          cardBlock: require("@/assets/images/svg/dash-card-block-1.svg"),
          cardEditIcon: require("@/assets/images/svg/dash-card-edit-icon.svg"),
        },
        {
          id: 3,
          title: "Rewards",
          amount: 0,
          showBal: true,
          class: 'rewards-card',
          cardBackground: require("@/assets/images/svg/dash-card-bg-3.svg"),
          cardIcon: require("@/assets/images/svg/dash-card-icon-3.svg"),
          cardBlock: require("@/assets/images/svg/dash-card-block-1.svg"),
          cardEditIcon: require("@/assets/images/svg/dash-card-edit-icon.svg"),
        },
      ],
    };
  },
  computed: {
    ...mapState(["userDetails"]),
  },
  watch: {
    userDetails(newVal) {
      this.cardData[0].amount = Math.round(Number(newVal.totalSavings));
    },
  },
  async mounted() {
    await this.setCardSettings();
    await this.populateAmount();
  },
  methods: {
    ...mapActions(["getSavedWallet"]),
    formatAsMoney,

    toggleSaving(index) {
      this.cardData[index].showBal = !this.cardData[index].showBal;
      localStorage.setItem(CARD_SETTINGS, JSON.stringify(this.cardData));
    },
    async setCardSettings() {
      let sliderData = JSON.parse(
        localStorage.getItem(CARD_SETTINGS)
      );
      if (sliderData) {
        sliderData = sliderData.filter(item => !!item.id);
        if (sliderData.length < 3) {
          sliderData = this.cardData;
          localStorage.setItem(CARD_SETTINGS, JSON.stringify(this.cardData));
        }

        this.cardData.forEach((card) => {
          const savedCard = sliderData.find((elm) => elm.id === card.id);
          card.showBal = savedCard.showBal;
        });
      }
    },
    async populateAmount() {
      if (this.userDetails) {
        this.cardData[0].amount = Number(this.userDetails.totalSavings);
        let interest = await this.getSavedWallet(WalletEnum.INTEREST);
        let commission = await this.getSavedWallet(WalletEnum.COMMISSION);
        this.cardData[1].amount = Number(interest.balance);
        this.cardData[2].amount = Number(commission.balance);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.total-savings-card {
  background: #FDAC90;
}

.interest-card {
  background: #7BA8A0;
}

.rewards-card {
  background: #E69BB0;
}

.card-wrapper {
  display: none;
}

.swipe-text {
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
}

.card-mobile-wrapper {
  display: block;

  .card-mobile-carousel {
    height: 30vh !important;
    position: relative;
  }

  .card-mobile {
    height: 23vh;
  }

  .card-mobile-top {
    border-radius: 20px;
  }

  .card-mobile-bottom {
    margin-top: -5px;
    width: 95%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    background-clip: content-box;
  }

  .card-mobile-content {
    height: 100%;
    padding: 1rem;
  }

  .card-mobile-text {
    color: white;
    font-size: 4.5vw;
    flex-basis: 100%;
  }

  .card-mobile-amount {
    font-size: 7vw;
  }

  .card-mobile-button {
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.3rem;
    border-radius: 5px;
    font-size: 0.8rem;
  }

  .card-mobile-button:hover,
  .card-mobile-button:active {
    cursor: pointer;
    background-color: transparent;
  }
}

@media only screen and (min-width: 360px) {
  .card-mobile-wrapper {

    .card-mobile-carousel {
      height: 22vh !important;
    }

    .card-mobile {
      height: 18vh;
    }

    .card-mobile-bottom {
      width: 95%;
      background-size: cover;
    }

    .card-mobile-content {
      padding-top: 1.5rem;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media only screen and (min-width: 768px) {
  .card-mobile-wrapper {
    display: none;
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    .card {
      flex: 0 0 45vw;
      height: 150px;
      border-radius: 20px;
    }

    .card-background {
      width: 93%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5%;
      border-radius: 25px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-clip: content-box;
    }

    .card-content-wrapper {
      display: flex;
      padding-top: 8.5%;
      padding-right: 16px;
      padding-left: 16px;
    }

    .card-icon {
      width: 40px;
    }

    .card-title-btn-wrapper {
      display: flex;
      color: #ffff;
    }

    .card-title {
      font-size: 0.9rem;
    }

    .card-btn {
      display: flex;
      text-wrap: nowrap;
      border: 1px solid white;
      margin-left: auto;
      padding: 0.3rem;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      background-color: rgba(255, 255, 255, 0.2);
    }

    .card-btn:hover {
      cursor: pointer;
      background-color: transparent;
    }

    .card-btn-icon {
      display: none;
    }

    .card-amount {

      p {
        color: #ffff;
        font-size: 1.3rem;
        margin-top: 3%;
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .card-wrapper {
    flex-wrap: nowrap;
    overflow-x: auto;

    .card {
      flex: 0 0 35vw;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .card-wrapper {
    flex-wrap: wrap;
    overflow: initial;

    .card {
      flex: 0 0 31vw;
    }
  }
}


@media only screen and (min-width: 1200px) {
  .card-wrapper {
    flex-wrap: nowrap;

    .card {
      flex: 0 0 23vw;
    }

    .card-content-wrapper {
      padding-right: 12px;
      padding-left: 12px;
    }

    .card-title {
      font-size: 1rem;
    }

    .card-btn {
      font-size: 0.66rem;
    }

    .card-btn-icon {
      display: block;
    }
  }
}

@media (min-width: 1920px) {
  .card-wrapper {
    gap: 2rem;

    .card {
      flex: 0 0 25vw;
    }

    .card-content-wrapper {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}
</style>