<template>
  <div class="pb-5">
    <div class="d-flex align-center px-3 mb-8">
      <div class="mr-4" @click="$router.back()">
        <v-img src="@/assets/images/svg/back-arrow.svg" width="20"></v-img>
      </div>
      <h3 class="mb-0">Ajo (Thrift Flex)</h3>
    </div>

    <v-container v-if="thriftflexData">
      <v-row>
        <v-col cols="12" md="5">
          <div class="white pa-4">
            <h3 class="mb-4">Savings details</h3>
            <small>Frequency</small>
            <v-select
              class=""
              v-model="form.frequency"
              :items="frequencyList"
              :disabled="loading"
              return-object
              item-text="name"
              :placeholder="loading ? 'loading' : 'Select Frequency'"
              item-value="id"
              :loading="loading"
              outlined
              :rules="[rules.required]"
            ></v-select>
            <fj-base-input
              placeholder="Enter Amount"
              labelOutside="Amount"
              v-model="form.amount"
              :rules="amountRules"
              type="number"
            />
            <span v-if="form.frequency">
              <b>Note</b>: {{ form.frequency.name }} savings have a minimum
              amount of <span class="red--text">₦{{ minimumAmount }}</span> and
              maximum amount of
              <span class="red--text">₦{{ maximumAmount }}</span>
            </span>
          </div>

          <div class="white pa-5 mt-5">
            <div class="payment">
              <h3 class="mb-4">Payment Method</h3>
              <div v-if="!cardDetails || cardDetails.length > 0">
                <div
                  class="details mb-4"
                  v-for="card in cardDetails"
                  :key="card.id"
                  @click="selectedCard(card.id)"
                  :class="{ active: selected == card.id }"
                >
                  <h3 class="mb-0">**** **** **** {{ card.last4 }}</h3>
                  <small class="d-block">{{ card.bank }}</small>
                </div>
              </div>
              <div
                @click="selectedCard('new')"
                :class="{ active: selected == 'new' }"
                class="py-5 text-center details font-weight-bold mb-4"
              >
                Pay with new card
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="5" class="d-flex flex-column">
          <div class="pa-5 white blur">
            <h3 class="mb-4">Overview</h3>

            <div class="mb-10">
              <div class="d-flex justify-space-between align-center pb-4">
                <small>Amount</small>
                <p class="font-weight-bold mb-0">
                  ₦{{ formatAsMoney(form.amount) }}
                </p>
              </div>
              <div class="d-flex justify-space-between align-center pb-4">
                <small>Frequency</small>
                <p
                  v-if="form.frequency"
                  class="text-uppercase font-weight-bold mb-0"
                >
                  {{ form.frequency.name }}
                </p>
              </div>

              <div class="d-flex justify-space-between align-center pb-4">
                <small>Duration</small>
                <p class="font-weight-bold mb-0">{{ duration }} Month</p>
              </div>

              <!-- <div class="d-flex justify-space-between align-center pb-4">
                <small>Interest Due</small>
                <p class="font-weight-bold mb-0">{{ interestDue }}%</p>
              </div> -->

              <!-- <div class="d-flex justify-space-between align-center pb-4">
                <small>Daily Interest</small>
                <p class="font-weight-bold mb-0">NGN {{ dailyInterest }}</p>
              </div> -->
              <div class="d-flex justify-space-between align-center pb-4">
                <small>Start Date</small>
                <p class="font-weight-bold mb-0">{{ startDate }}</p>
              </div>
              <div class="d-flex justify-space-between align-center pb-4">
                <small>End Date</small>
                <p class="font-weight-bold mb-0">{{ endDate }}</p>
              </div>
            </div>

            <small class="note mb-9">
              <b>Note:</b> By Proceeding to payment, you've automatically
              subscribed to Fajo Monie autodebit from your bank account for the
              period of the Savings tenure.
            </small>
            <fj-base-button
              :disabled="!isComplete"
              block
              :loading="initiate"
              @click="startSavings()"
            >
              Pay Now
            </fj-base-button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <fj-page-loader v-else />

    <svg id="svg-filter">
      <filter id="svg-blur">
        <feGaussianBlur
          in="SourceGraphic"
          :stdDeviation="!isComplete ? 4 : 0"
        ></feGaussianBlur>
      </filter>
    </svg>
    <!-- ============ PAYSTACK CALL BUTTON ========= -->
    <paystack
      :amount="amount"
      :email="email"
      :paystackkey="this.$paystackKey"
      :reference="reference"
      :channels="channels"
      :callback="verify"
      :close="close"
    >
      Make Payment
    </paystack>
    <fj-response-modal
      :openModal="openModal"
      :status="status"
      :title="title"
      :message="message"
      callbackUrl="Thrift Flex"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FjResponseModal from "../../../components/modals/FjResponseModal";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import rules from "@/utils/rules";
import paystack from "vue-paystack";

export default {
  data() {
    return {
      rules,
      openModal: false,
      selected: null,
      options: ["payonline", "paybycard"],
      status: "",
      message: "",
      title: "",
      email: "",
      amount: 0,
      reference: "",
      form: {
        amount: null,
        frequency: null,
      },
      amountRules: [(value) => !!value || "Required"],
      frequencyList: [],
      loading: false,
      initiate: false,
      channels: [],
    };
  },
  async mounted() {
    await this.loadForm();
  },
  components: {
    FjResponseModal,
    paystack,
  },
  computed: {
    ...mapState(["cardDetails"]),
    ...mapState({
      thriftflexData: "Thriftflex",
    }),
    paymentMethod() {
      let methods;
      if (this.selected == "new") {
        methods = this.options[0];
      } else {
        methods = this.options[1];
      }
      return methods;
    },
    duration() {
      return 1;
    },
    startDate() {
      return formatDate(new Date());
    },
    endDate() {
      let date = new Date();
      date.setMonth(date.getMonth() + this.duration);
      return formatDate(date);
    },
    minimumAmount() {
      return this.filterWallet().savings[this.form.frequency.name].minimum;
    },
    maximumAmount() {
      return this.filterWallet().savings[this.form.frequency.name].maximum;
    },
    verifyAmount() {
      return (
        this.form.amount <=
          this.filterWallet().savings[this.form.frequency.name].maximum &&
        this.form.amount >=
          this.filterWallet().savings[this.form.frequency.name].minimum
      );
    },
    isComplete() {
      return (
        this.form.amount &&
        this.form.frequency &&
        this.selected &&
        this.verifyAmount
      );
    },
  },
  methods: {
    ...mapActions("thriftflex", ["getFrequency"]),
    ...mapActions("cards", ["getCards"]),
    ...mapActions("thriftflex", ["startThriftflex", "getThriftflex"]),
    ...mapActions("payments", ["verifyPayments"]),

    async loadForm() {
      this.loading = true;
      const [frequency] = await Promise.all([
        this.getFrequency(),
        this.loadSetup(),
      ]);
      this.frequencyList = frequency;
      this.loading = false;
    },

    async loadSetup() {
      await this.getCards();
      if (!this.thriftflexData) {
        await this.getThriftflex();
      }
    },

    selectedCard(idx) {
      this.selected = idx;
    },

    async startSavings() {
      try {
        this.initiate = true;
        let payload = {
          amount: Number(this.form.amount),
          frequency: this.form.frequency.id,
          request: this.paymentMethod,
          cardauthid: this.selected == "new" ? null : this.selected,
        };
        const data = await this.startThriftflex(payload);
        if (this.paymentMethod == this.options[1]) {
          this.verify();
        } else if (this.paymentMethod == this.options[0]) {
          this.initializePaystack(data);
        }
        this.initiate = false;
      } catch (error) {
        this.errorMessage();
      }
    },

    async initializePaystack(data) {
      const paystackInitializer = document.querySelector(".payButton");
      this.reference = data.payload.reference;
      this.amount = data.payload.amount;
      this.email = data.payload.email;
      this.channels = data.payload.payment_channel;
      paystackInitializer.click();
    },

    async successMessage() {
      this.title = "Transaction Successful";
      this.message =
        "Your subscription to 30-day Ajo has been successfully activated, check account for details";
      this.status = "success";
      this.transactionStatus = "done";
      this.openModal = true;
      this.initiate = false;
    },

    async errorMessage() {
      this.title = "Something went wrong";
      this.message =
        "Your subscription to 30-day Ajo is not verified, please verify details and try again";
      this.status = "failed";
      this.transactionStatus = "done";
      this.openModal = true;
      this.initiate = false;
    },

    async verify(data) {
      try {
        if (data) {
          await this.verifyPayments({
            reference: data.reference,
          });
        }
        this.successMessage();
      } catch (error) {
        this.errorMessage();
      } finally {
        let self = this;
        setTimeout(() => {
          self.getThriftflex();
          self.$store.dispatch("user/fetchUser");
        }, 4000);
      }
    },

    async close() {
      try {
        await this.verifyPayments({
          reference: this.reference,
        });
        this.errorMessage();
      } catch (error) {
        this.errorMessage();
      } finally {
        let self = this;
        setTimeout(() => {
          self.getThriftflex();
          self.$store.dispatch("user/fetchUser");
        }, 4000);
      }
    },

    formatAsMoney(money) {
      let Numbermoney = Number(money);
      return formatAsMoney(Numbermoney);
    },

    filterWallet(query) {
      let thriftflexWallet = this.thriftflexData.walletDetails;
      if (query) {
        return thriftflexWallet.query;
      }
      return thriftflexWallet;
    },

    initiateSaving() {
      this.openModal = !this.openModal;
    },
  },
};
</script>
note{}

<style lang="scss" scoped>
.note {
  line-height: 1.6;
  display: block;
}
.payment {
  .details {
    width: 100%;
    border: 1px solid #000;
    padding: 14px 24px;

    small {
      display: block;
      margin-top: -6px;
    }

    &.active {
      border: 1px solid var(--primary);
      background: rgba(247, 81, 22, 0.1);
    }
  }
}
.blur {
  // backdrop-filter: blur(90px);
  -webkit-filter: url(#svg-blur);
  filter: url(#svg-blur);
}
</style>
