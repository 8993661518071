<template>
  <v-container>
    <div class="profile">
      <h3 class="mb-5">Settings</h3>
      <div class="profile-tab">
        <div class="d-flex tabs-wrapper">
          <div
            class="port-tabs d-flex align-center pointer px-4 py-4 mr-1"
            v-for="(tab, idx) in profileTabs"
            :key="idx"
            @click="switchTab(idx, tab)"
            :class="{ active: active == idx }"
          >
            <img :src="tab.cardIcon" width="15" alt="" />
            <p class="mb-0 ml-3">{{ tab.title }}</p>
          </div>
        </div>
      </div>
    </div>

    <router-view></router-view>
  </v-container>
</template>

<script>
// import ProfileMenu from "../com/ponents/ProfileMenu.vue";
export default {
  data() {
    return {
      active: 0,
      profileTabs: [
        {
          cardIcon: require("@/assets/images/png/user.png"),
          title: "Edit my profile",
          link: "My Profile",
        },
        {
          cardIcon: require("@/assets/images/png/credit-card.png"),
          title: "My cards",
          link: "My Cards",
        },
        {
          cardIcon: require("@/assets/images/png/chart.png"),
          title: "Bank accounts",
          link: "Bank Account",
        },
        {
          cardIcon: require("@/assets/images/png/lock.png"),
          title: "Change pin",
          link: "Change Pin",
        },
        // {
        //   cardIcon: require("@/assets/images/png/lock.png"),
        //   title: "KYC",
        //   link: "KYC",
        // },
        {
          cardIcon: require("@/assets/images/png/chat.png"),
          title: "Support",
          link: "Support",
        },
        {
          cardIcon: require("@/assets/images/png/lock.png"),
          title: "About",
          link: "About",
        },
      ],
    };
  },
  mounted() {
    this.setActiveTab();
  },

  methods: {
    setActiveTab() {
      let routePath = this.$route.name;
      let routeIdx = this.profileTabs.findIndex((elm) => elm.link == routePath);
      this.active = routeIdx;
    },
    switchTab(idx, tab) {
      this.active = idx;
      if (this.$route.name == tab.link) return;
      this.$router.push({ name: tab.link });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-container {
  padding-bottom: 20px;
}

.profile-tab {
  overflow: auto;
}

.tabs-wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.116);

  @media screen and (max-width: 768px) {
    width: 150vw;
  }
  @media screen and (max-width: 700px) {
    width: 270vw;
  }
  @media screen and (max-width: 400px) {
    width: 290vw;
  }

  .port-tabs {
    box-sizing: border-box;
    opacity: 0.4;
    &.active {
      opacity: 1;
      border-bottom: 2px solid var(--primary);
    }
  }
}
</style>
