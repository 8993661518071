<template>
  <div class="pb-5">
    <div class="d-flex align-center px-3 mb-8">
      <div class="mr-4 pointer" @click="$router.back()">
        <v-img src="@/assets/images/svg/back-arrow.svg" width="20"></v-img>
      </div>
      <h3 class="mb-0">Power Save</h3>
    </div>

    <v-container v-if="powersaveData">
      <v-row>
        <v-col cols="12" md="5">
          <div class="white pa-4">
            <h3 class="mb-4">Savings details</h3>
            <div>
              <label for="savings-purpose">Savings Purpose</label>
              <v-select
                id="savings-purpose"
                v-model="form.purpose"
                :items="purposeList"
                :disabled="loading"
                :loading="loading"
                :rules="[rules.required]"
                placeholder="Select Purpose For Saving"
                outlined
              ></v-select>
            </div>
            <div>
              <label for="savings-target">Savings Target (How much would you like to save?)</label>
              <fj-base-input
                id="savings-target"
                placeholder="Enter Amount"
                class="mb-0"
                hide-details
                v-model="form.savings_target"
                :rules="savingsTragetRules"
                type="number"
              />
            </div>
            <div class="mb-4">
              <label for="">Duration</label>
              <v-row>
                <v-col class="pb-0">
                  <v-select
                    :items="['Months']"
                    placeholder="Years/Months"
                    outlined
                    hide-details
                    v-model="form.duration"
                  ></v-select>
                </v-col>
                <v-col class="pb-0">
                  <fj-base-input
                    placeholder="How long e.g 7"
                    hide-details
                    type="number"
                    v-model="form.durationNo"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
              <small>
                <v-icon color="#f75116">mdi-information-outline</v-icon>
                Powersave savings have a minimum duration of
                <span class="red--text">
                  {{ this.filterWallet().duration.minimum }} Months
                </span>
              </small>
            </div>
            <div class="autosave-action mb-3">
              <div class="mr-5">
                <p class="mb-1">Enable AutoSave</p>
                <small>
                  Save automatically daily, weekly or monthly 
                  to achieve your savings target
                </small>
              </div>
              <div>
                <v-switch
                  v-model="form.is_autosave"
                  class="mt-0 mb-0"
                  inset
                ></v-switch>
              </div>
            </div>
            <div v-if="form.is_autosave">
              <label for="frequency">How often do you prefer to save</label>
              <v-select
                id="frequency"
                v-model="form.frequency"
                :items="frequencyList"
                :disabled="loading"
                :loading="loading"
                placeholder="Select Frequency"
                item-text="name"
                item-value="id"
                return-object
                outlined
                :rules="[rules.required]"
              ></v-select>
            </div>
            <div v-if="form.is_autosave">
              <label for="savings-amount">Amount to save per time</label>
              <fj-base-input
                id="savings-amount"
                placeholder="Enter Amount"
                class="mb-0"
                hide-details
                v-model="form.amount"
                :rules="amountRules"
                type="number"
                />
              <small v-if="form.frequency">
                <v-icon color="#f75116">mdi-information-outline</v-icon>
                {{ form.frequency.name }}
                {{ minimumAmount ? " savings have a minimum amount of" : "" }}
                <span class="red--text" v-if="minimumAmount">
                  ₦{{ minimumAmount }}
                </span>
                {{ minimumAmount && maximumAmount ? "and" : "" }}
                {{ maximumAmount ? " a maximum amount of" : "" }}
                <span class="red--text" v-if="maximumAmount">
                  ₦{{ maximumAmount }}
                </span>
              </small>
            </div>
          </div>

          <div class="white pa-5 mt-5">
            <div class="payment">
              <h3 class="mb-4">Payment Method</h3>
              <div v-if="!cardDetails || cardDetails.length > 0">
                <div
                  class="details mb-4"
                  v-for="card in cardDetails"
                  :key="card.id"
                  @click="selectedCard(card.id)"
                  :class="{ active: selected == card.id }"
                >
                  <h3 class="mb-0">**** **** **** {{ card.last4 }}</h3>
                  <small class="d-block">{{ card.bank }}</small>
                </div>
              </div>
              <div
                @click="selectedCard('new')"
                :class="{ active: selected == 'new' }"
                class="py-5 text-center details font-weight-bold mb-4"
              >
                {{ form.is_autosave ? 'Pay with new card' : 'Pay via Paystack' }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="5" class="d-flex flex-column">
          <div class="white pa-5 blur">
            <h3 class="mb-4">Overview</h3>

            <div class="mb-10">
              <div class="d-flex justify-space-between align-center pb-4">
                <small>Amount</small>
                <p class="font-weight-bold mb-0">
                  ₦{{ formatAsMoney(amount) }}
                </p>
              </div>
              <div
                v-if="form.is_autosave"
                class="d-flex justify-space-between align-center pb-4"
              >
                <small>Frequency</small>
                <p v-if="form.frequency" class="font-weight-bold mb-0">
                  {{ form.frequency.name }}
                </p>
              </div>
              <div class="d-flex justify-space-between align-center pb-4">
                <small>Duration</small>
                <p class="font-weight-bold mb-0">
                  {{ form.durationNo }} {{ form.duration }}
                </p>
              </div>
              <div class="d-flex justify-space-between align-center pb-4">
                <small>Purpose</small>
                <p class="font-weight-bold mb-0">
                  {{ form.purpose }}
                </p>
              </div>

              <div class="d-flex justify-space-between align-center pb-4">
                <small>Interest Due</small>
                <p class="font-weight-bold mb-0">{{ interestDue }}%</p>
              </div>

              <div class="d-flex justify-space-between align-center pb-4">
                <small>Monthly Interest</small>
                <p class="font-weight-bold mb-0">NGN {{ monthlyInterest }}</p>
              </div>
              <div class="d-flex justify-space-between align-center pb-4">
                <small>Start Date</small>
                <p class="font-weight-bold mb-0">{{ startDate }}</p>
              </div>
              <div class="d-flex justify-space-between align-center pb-4">
                <small>End Date</small>
                <p class="font-weight-bold mb-0">{{ endDate }}</p>
              </div>
            </div>

            <small class="note mb-9">
              <b>Note:</b> By Proceeding to payment, you've automatically
              subscribed to Fajo Monie autodebit from your bank account for the
              period of the Savings tenure.
            </small>
            <fj-base-button
              :disabled="!isComplete"
              block
              :loading="initiate"
              @click="startSavings()"
            >
              Pay Now
            </fj-base-button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <fj-page-loader v-else />

    <svg id="svg-filter">
      <filter id="svg-blur">
        <feGaussianBlur
          in="SourceGraphic"
          :stdDeviation="!isComplete ? 4 : 0"
        ></feGaussianBlur>
      </filter>
    </svg>
    <!-- ============ PAYSTACK CALL BUTTON ========= -->
    <paystack
      :paystackkey="this.$paystackKey"
      :amount="Number(pystkDetails.amount)"
      :email="pystkDetails.email"
      :reference="pystkDetails.reference"
      :channels="pystkDetails.channels"
      :callback="verify"
      :close="close"
    >
      Make Payment
    </paystack>

    <fj-response-modal
      :openModal="openModal"
      :status="status"
      :title="title"
      :message="message"
      callbackUrl="Power Save"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FjResponseModal from "../../../components/modals/FjResponseModal";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import rules from "@/utils/rules";
import paystack from "vue-paystack";

export default {
  data() {
    return {
      rules,
      loading: false,
      initiate: false,
      openModal: false,
      selected: null,
      options: ["payonline", "paybycard"],
      status: "",
      message: "",
      title: "",
      minSavingsTarget: 0,
      form: {
        amount: null,
        durationNo: "",
        duration: "Months",
        frequency: null,
        purpose: "",
        savings_target: null,
        is_autosave: false,
      },
      pystkDetails: {
        amount: 0,
        reference: "",
        email: "",
        channels: [],
      },
      durationRules: [(value) => !!value || "Required"],
      amountRules: [
        (value) => !!value || "Required",
        (value) => value > 0 || "Invalid number",
      ],
      savingsTragetRules: [
        (value) => !!value || "Required",
        (value) => value > 0 || "Invalid number",
      ],
      frequencyList: [],
      purposeList: [],
    };
  },
  async mounted() {
    await this.loadForm();
  },
  components: {
    FjResponseModal,
    paystack,
  },
  computed: {
    ...mapState(["cardDetails"]),
    ...mapState({
      powersaveData: "Powersave",
    }),
    paymentMethod() {
      let methods;
      if (this.selected == "new") {
        methods = this.options[0];
      } else {
        methods = this.options[1];
      }
      return methods;
    },
    amount() {
      return this.form.is_autosave ?
        this.form.amount :
        this.form.savings_target;
    },
    duration() {
      let duration;
      if (this.form.duration == "Months") {
        duration = Number(this.form.durationNo);
      } else if (this.form.duration == "Years") {
        duration = Number(this.form.durationNo) * 12;
      }
      return duration;
    },
    interestDue() {
      return this.filterWallet().yearly_interest_percentage;
    },
    monthlyInterest() {
      let amountInKobo = Number(this.amount) * 100;
      let interest = Number(this.filterWallet().monthly_interest);
      let result = (amountInKobo * interest).toFixed();
      return Number(result) / 100;
    },
    startDate() {
      return formatDate(new Date());
    },
    endDate() {
      let date = new Date();
      date.setMonth(date.getMonth() + this.duration);
      return formatDate(date);
    },
    minimumAmount() {
      return this.filterWallet().savings[this.form.frequency.name].minimum;
    },
    maximumAmount() {
      return this.filterWallet().savings[this.form.frequency.name].maximum;
    },
    verifyAmount() {
      if (this.minimumAmount && this.maximumAmount) {
        return (
          this.amount <=
            this.filterWallet().savings[this.form.frequency.name].maximum &&
          this.amount >=
            this.filterWallet().savings[this.form.frequency.name].minimum
        );
      }

      if (this.minimumAmount && !this.maximumAmount) {
        return this.amount >= this.minimumAmount;
      }

      return false;
    },
    verifyDuration() {
      return this.duration >= this.filterWallet().duration.minimum;
    },
    verifySavingsTarget() {
      return this.form.savings_target >= this.minSavingsTarget;
    },
    isComplete() {
      if (this.form.is_autosave) {
        return (
          this.form.purpose &&
          this.form.amount &&
          this.form.frequency &&
          this.selected &&
          this.verifyDuration &&
          this.verifyAmount &&
          this.verifySavingsTarget
        );
      }
      else {
        return (
          this.form.purpose &&
          this.selected &&
          this.verifyDuration &&
          this.verifySavingsTarget
        );
      }
    },
  },
  methods: {
    ...mapActions({
      getFrequency: 'powersave/getFrequency',
      getCards: 'cards/getCards',
      startPowersave: 'powersave/startPowersave',
      getPowersave: 'powersave/getPowersave',
      verifyPayments: 'payments/verifyPayments',
      fetchPurposeList: 'user/getSavingsPurpose',
    }),

    async loadForm() {
      this.loading = true;
      const [frequency, purposeList] = await Promise.all([
        this.getFrequency(),
        this.fetchPurposeList(),
        this.getCards(),
        this.loadWalletSetup(),
      ]);
      this.frequencyList = frequency;
      this.purposeList = purposeList;
      this.minSavingsTarget = this.powersaveData.walletDetails?.min_savings_target || 0;
      this.savingsTragetRules.push(
        (value) => value >= this.minSavingsTarget || `Minimum amount of ₦${this.minSavingsTarget}`
      );
      this.loading = false;
    },

    async loadWalletSetup() {
      if (!this.powersaveData) {
        await this.getPowersave();
      }
    },

    selectedCard(idx) {
      this.selected = idx;
    },

    filterWallet(query) {
      let powersaveWallet = this.powersaveData.walletDetails;
      if (query) {
        return powersaveWallet.query;
      }
      return powersaveWallet;
    },

    async startSavings() {
      try {
        this.initiate = true;
        const payload = {
          amount: Number(this.amount),
          frequency: this.form.frequency?.id,
          duration: this.duration,
          request: this.paymentMethod,
          cardauthid: this.selected == "new" ? null : this.selected,
          purpose: this.form.purpose,
          savings_target: Number(this.form.savings_target),
          is_autosave: this.form.is_autosave,
        };
        const data = await this.startPowersave(payload);
        if (this.paymentMethod == this.options[1]) {
          this.verify();
        } else if (this.paymentMethod == this.options[0]) {
          this.initializePaystack(data);
        }
        this.initiate = false;
      } catch (error) {
        this.errorMessage();
      }
    },

    async initializePaystack(data) {
      const paystackInitializer = document.querySelector(".payButton");
      this.pystkDetails.reference = data.payload.reference;
      this.pystkDetails.amount = data.payload.amount;
      this.pystkDetails.email = data.payload.email;
      this.pystkDetails.channels = data.payload.payment_channel;
      paystackInitializer.click();
    },

    async successMessage() {
      this.title = "Transaction Successful";
      this.message =
        "Your subscription to Power save has been successfully activated, check account for details";
      this.status = "success";
      this.transactionStatus = "done";
      this.openModal = true;
      this.initiate = false;
    },

    async errorMessage() {
      this.title = "Something went wrong";
      this.message =
        "Your subscription to Power save is not verified, please verify details and try again";
      this.status = "failed";
      this.transactionStatus = "done";
      this.openModal = true;
      this.initiate = false;
    },

    async verify(data) {
      try {
        if (data) {
          await this.verifyPayments({
            reference: data.reference,
          });
        }
        this.successMessage();
      } catch (error) {
        this.errorMessage();
      } finally {
        setTimeout(() => {
          this.getPowersave();
          this.$store.dispatch("user/fetchUser");
        }, 4000);
      }
    },

    formatAsMoney(money) {
      let Numbermoney = Number(money);
      return formatAsMoney(Numbermoney);
    },

    async close() {
      try {
        await this.verifyPayments({
          reference: this.pystkDetails.reference,
        });
        this.errorMessage();
      } catch (error) {
        this.errorMessage();
      } finally {
        setTimeout(() => {
          this.getProjectsave();
          this.$store.dispatch("user/fetchUser");
        }, 4000);
      }
    },

    initiateSaving() {
      this.openModal = !this.openModal;
    },
  },
};
</script>
note{}

<style lang="scss" scoped>
.note {
  line-height: 1.6;
  display: block;
}
.payment {
  .details {
    width: 100%;
    border: 1px solid #000;
    padding: 14px 24px;

    small {
      display: block;
      margin-top: -6px;
    }

    &.active {
      border: 1px solid var(--primary);
      background: rgba(247, 81, 22, 0.1);
    }
  }
}
.blur {
  // backdrop-filter: blur(90px);
  -webkit-filter: url(#svg-blur);
  filter: url(#svg-blur);
}
.autosave-action {
  padding: 0.5rem 1rem;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  background: #f2f6fc;
  color: #202124;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
}
</style>
