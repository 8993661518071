<template>
  <div>
    <v-container>
      <div class="" v-if="!hide_tabs">
        <h3 class="mb-5">{{ portfolioTabs[active].title }}</h3>
        <div class="port-tabs-holder">
          <div class="d-flex tabs-wrapper">
            <div
              class="port-tabs d-flex pointer px-4 py-4"
              v-for="(tab, idx) in filteredTabs"
              :key="idx"
              @click="switchTab(idx, tab)"
              :class="{ active: active == idx }"
            >
              <div class="img_container">
                <img style="max-width: 100%;" :src="tab.cardIcon" alt="" />
              </div>
              <span class="mb-0 ml-2">{{ tab.title }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!hide_card">
        <h3 class="mb-4">Portfolio</h3>
        <div class="card_container" v-if="cardData">
          <div class="card" v-for="(item, index) in cardData" :key="index">
            <div class="">
              <v-img :src="item.cardIcon" class="card-icon"></v-img>
            </div>
            <div class="ml-4 white--text">
              <h4 class="white--text">{{ item.title }}</h4>
              <p class="mb-0 white--text" v-if="item.showBal">
                ₦{{ formatAsMoney(item.amount) }}
              </p>
              <p class="mb-0 white--text" v-else>******</p>
            </div>
            <v-icon
              color="white"
              class="ml-auto"
              v-if="item.showBal"
              @click="toggleSaving(index)"
            >
              mdi-eye-off
            </v-icon>
            <v-icon
              color="white"
              class="ml-auto"
              v-else
              @click="toggleSaving(index)"
            >
              mdi-eye
            </v-icon>
          </div>
        </div>
      </div>

      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { formatAsMoney } from "@/utils/helpers";

export default {
  data() {
    return {
      active: 0,
      hide_tabs: true,
      hide_card: true,
      portfolioTabs: [
        {
          cardIcon: require("@/assets/images/svg/dash-flex.svg"),
          amount: "0.00",
          title: "Ajo (Thrift Flex)",
          link: "thrift-flex",
          name: 'AJO',
          show: true,
        },
        {
          cardIcon: require("@/assets/images/svg/thumb-up.svg"),
          amount: "0.00",
          title: "Project Save",
          link: "project-save",
          name: 'PROJECTSAVE',
          show: true,
        },
        {
          cardIcon: require("@/assets/images/svg/power.svg"),
          amount: "0.00",
          title: "Power Save",
          link: "power-save",
          name: 'POWERSAVE',
          show: true,
        },
        {
          cardIcon: require("@/assets/images/svg/power.svg"),
          amount: "0.00",
          title: "Super Save",
          link: "super-save",
          name: 'SUPERSAVE',
          show: false,
        },
        {
          cardIcon: require("@/assets/images/svg/percent-port.svg"),
          amount: "0.00",
          title: "Emergency Funds",
          link: "emergency-funds",
          name: 'LOAN',
          show: true,
        },
        {
          cardIcon: require("@/assets/images/svg/interest.svg"),
          amount: "0.00",
          title: "My Interest",
          link: "interest",
          name: 'INTEREST',
          show: true,
        },
      ],
      cardData: [
        {
          cardIcon: require("@/assets/images/svg/saving-icon.svg"),
          title: "Total Savings",
          amount: 0,
          showBal: true,
        },
      ],
    };
  },
  computed: {
    filteredTabs() {
      return this.portfolioTabs.filter(item => item.show)
    }
  },
  watch: {
    $route() {
      this.hideTabs();
    },
  },
  components: {},

  mounted() {
    this.portfolioTabs.forEach(async (tab) => {
      if (tab.name == 'SUPERSAVE') {
        const wallet = await this.getSavedWallet('SUPERSAVE');
        tab.show = !!wallet;
      }
    });
    this.hideTabs();
    this.setActiveTab();
    // if (!this.hidePortfolioTab) {
    //   let sliderData = JSON.parse(localStorage.getItem("balanceCardStatus"));
    //   const savingsCard = sliderData.find(
    //     (item) => item.title === "Total Savings"
    //   );
    //   this.cardData = [savingsCard];
    // }
  },

  methods: {
    ...mapActions(["getSavedWallet"]),
    ...mapActions("history", ["saveModule"]),
    formatAsMoney,
    async hideTabs() {
      let routePath = this.$route.path.split("/");
      if (routePath.length < 3) {
        this.hide_card = false;
        this.hide_tabs = true;
      } else if (routePath.length == 3) {
        this.hide_tabs = false;
        this.hide_card = true;
      } else {
        this.hide_card = true;
        this.hide_tabs = true;
      }
      // routePath.length == 3 ? (this.hide_tabs = true) : (this.hide_tabs = false);
    },
    setActiveTab() {
      let routePath = this.$route.path.split("/");
      // if (routePath.length == 3) {
        let routeIdx = this.portfolioTabs.findIndex(
          (elm) => elm.link == routePath[2].toLowerCase()
        );
        if (routeIdx) {
          this.active = routeIdx;
        }
      // }
    },
    switchTab(idx, tab) {
      this.active = idx;
      this.saveModule(tab.name);
      const path = "/portfolio/" + tab.link;
      if (path == this.$route.path) return;
      this.$router.push(path);
    },
    toggleSaving(index) {
      this.cardData[index].showBal = !this.cardData[index].showBal;
      let sliderData = JSON.parse(localStorage.getItem("balanceCardStatus"));
      const cardIndex = sliderData.findIndex(
        (item) => item.title === "Total Savings"
      );
      sliderData[cardIndex] = this.cardData[index];
      localStorage.setItem("balanceCardStatus", JSON.stringify(sliderData));
    },
  },
};
</script>
<style lang="scss" scoped>
h4 {
  color: #7c280b;
}

.card_container {
  width: 100%;
  overflow: auto;
  display: flex;

  .card {
    flex-basis: 33%;
    background-color: var(--primary);
    display: flex;
    padding: 0 1rem;
    height: 100px;
    display: flex;
    align-items: center;

    p {
      font-size: 1.17em;
    }

    .card-icon {
      width: 40px;
    }
  }
}

.active {
  // border-bottom: 2px solid #000;
}

.port-tabs-holder {
  overflow: auto;
}

.tabs-wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.116);
  overflow: auto;

  @media screen and (max-width: 700px) {
    width: 150vw;
  }
  @media screen and (max-width: 400px) {
    width: 160vw;
  }

  .port-tabs {
    box-sizing: border-box;
    opacity: 0.4;
    flex-grow: 1;
    &:first-child {
      color: var(--secondary);
    }
    &:nth-child(2) {
      color: var(--purple);
    }
    &:nth-child(3) {
      color: var(--info);
    }
    &:nth-child(4) {
      color: #000;
    }
    &:first-child.active {
      border-bottom: 2px solid var(--secondary);
    }
    &:nth-child(2).active {
      border-bottom: 2px solid var(--purple);
    }
    &:nth-child(3).active {
      border-bottom: 2px solid var(--info);
    }
    &:nth-child(4).active {
      border-bottom: 2px solid #000;
    }
    &:nth-child(5).active {
      border-bottom: 2px solid var(--info);
    }
    &.active {
      opacity: 1;
    }

    >.img_container {
      width: 15px;
    }

    @media screen and (max-width: 768px) {
      p {
        font-size: 13px;
      }
    }
  }
}
</style>
