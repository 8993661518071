import { render, staticRenderFns } from "./LoanRequest.vue?vue&type=template&id=2785a335&scoped=true&"
import script from "./LoanRequest.vue?vue&type=script&lang=js&"
export * from "./LoanRequest.vue?vue&type=script&lang=js&"
import style0 from "./LoanRequest.vue?vue&type=style&index=0&id=2785a335&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2785a335",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCol,VContainer,VForm,VImg,VRow,VSelect})
