<template>
  <div class="white pa-7 history">
    <div class="d-flex justify-space-between">
      <!-- <h4 class="mb-0">History</h4> -->
      <v-tabs>
        <v-tab
          v-for="(item, index) in tabs"
          :key="index"
          @click="selectTab(item.entity)"
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>
    </div>

    <transaction-history v-if="entity == 'transaction'" />
    <withdrawal-history v-if="entity == 'withdrawal'" />
    <loan-payment-history v-if="entity == 'emergency_funds_payment'" />
    <loan-history v-if="entity === 'emergency_funds'" />
    <interest-history v-if="entity === 'interest_history'" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TransactionHistory from "./TransactionHistory.vue";
import WithdrawalHistory from "./WithdrawalHistory.vue";
import LoanPaymentHistory from "./LoanPaymentHistory.vue";
import LoanHistory from "./LoanHistory.vue";
import InterestHistory from "./InterestHistory.vue"

export default {
  data() {
    return {
      tabs: [
        {
          title: "History",
          entity: "transaction",
        },
        {
          title: "Withdrawals",
          entity: "withdrawal",
        },
      ],
      entity: "transaction",
    };
  },
  props: {
    defaultTabs: {
      type: Array,
      default: () => [],
    },
    defaultEntity: {
      type: String,
      default: null
    },
  },
  components: {
    TransactionHistory,
    WithdrawalHistory,
    LoanPaymentHistory,
    LoanHistory,
    InterestHistory,
  },
  computed: {},
  async mounted() {
    if (this.defaultTabs.length > 0) {
      this.tabs = this.defaultTabs
    }
    if (this.defaultEntity) {
      this.entity = this.defaultEntity
    }
  },
  watch: {
    tabs(newValue) {
      this.tabs = newValue;
    },
  },
  methods: {
    ...mapActions(["getSavedWallet"]),

    async selectTab(val) {
      this.entity = val
    },
  },
};
</script>

<style lang="scss" scoped></style>
