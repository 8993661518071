<template>
  <div class="h-100">
    <div v-if="history.length > 0" class="activity-feed mt-6">
      <div
        class="d-flex align-center feed-item"
        v-for="(item, index) in history"
        :key="index"
      >
        <div class="details">
          <p class="mb-0">
            {{ getPaymentMonth(item.created_at) }}
          </p>
          <small>{{ formatDate(item.created_at) }} </small>
        </div>

        <div class="ml-auto">
          <p
            class="mb-0 font-weight-bold"
            :class="item.status == 'failed' ? 'error--text' : 'secondary--text'"
            v-if="item.transaction_amount"
          >
            N{{ formatAsMoney(item.transaction_amount) }}
          </p>
          <small
            :class="item.status == 'failed' ? 'error--text' : 'secondary--text'"
          >
            {{ item.status }}
          </small>
        </div>
      </div>

      <infinite-loading spinner="waveDots" @infinite="infiniteHandler">
        <span slot="no-results"></span>
        <span slot="no-more"></span>
      </infinite-loading>
    </div>

    <div
      v-if="history.length == 0 && !loading"
      class="d-flex mt-10 h-100 justify-center align-center"
    >
      <p>No Payment History</p>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      page: 1,
      loading: false,
      history: [],
    };
  },
  computed: {
    ...mapState("history", ["moduleName"]),
  },
  components: {
    InfiniteLoading,
  },
  async mounted() {
    await this.fetchTransactions();
  },
  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions(["getSavedWallet"]),
    ...mapActions("history", ["getTransactions"]),

    getPaymentMonth(timeStamp) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return `${months[new Date(timeStamp).getMonth()]} Payment`;
    },

    async fetchTransactions() {
      try {
        this.loading = true;
        let { payload } = await this.getTransactions({
          transaction_type: "loan_payment",
          page: this.page,
        });
        this.history = this.history.concat(
          payload.rows.filter(
            (item) => item.status === "success" || item.status === "failed"
          )
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    infiniteHandler($state) {
      setTimeout(async () => {
        this.page++;
        let data = await this.fetchTransactions();
        if (data && data.length > 0) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
