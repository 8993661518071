<template>
  <div class="mt-7">
    <v-row v-if="!loading">
      <v-col cols="12" md="6">
        <div class="pa-7 activity">
          <div class="d-flex justify-space-between">
            <h4 class="mb-0">Recent activities</h4>
          </div>

          <div class="activity-list mt-3" v-if="activityLogs.length > 0">
            <div
              class="activity-list-item"
              v-for="(item, index) in activityLogs"
              :key="index"
            >
              <div>
                <p class="mb-0">{{ item.action }}</p>
                <small>{{ formatDate(item.created_at) }} </small>
              </div>

              <p
                class="mb-0 ml-auto font-weight-bold"
                v-if="item.amount"
              >
                N{{ formatAsMoney(item.amount) }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          v-if="banners && banners.length > 0"
          class="mb-10 d-none d-md-block"
        >
          <DashboardBanner :banners="banners"></DashboardBanner>
        </div>
        <div
          v-if="notifications && notifications.length > 0"
          class="white pa-7 notification-wrapper"
        >
          <h4>Notifications</h4>
          <div v-if="notifications.length > 0">
            <div
              v-for="item in notifications"
              :key="item.id"
              class="banner py-3 px-6 mb-3 mt-3"
            >
              <small class="white--text">{{ item.message }}.</small>

              <div class="mt-3">
                <v-btn class="text-capitalize mr-2" @click="approveSurety(item)"
                  >Accept</v-btn
                >
                <v-btn
                  class="text-capitalize mr-2"
                  @click="disapproveSurety(item)"
                  >Decline</v-btn
                >
              </div>
            </div>
          </div>
          <div class="no-notification" v-else>
            <p>No Notification</p>
          </div>
        </div>
        <div class="social-links-wrapper">
          <div class="social-link">
            <a href="https://wa.me/2348105234151" target="_blank">
              <div class="whatsapp-link">
                <span class="whatsapp-link-text">LIVE CHAT</span>
                <img :src="require('@/assets/images/svg/whatsapp-icon-portfolio.svg')" alt="WhatsApp Icon">
              </div>
            </a>
          </div>
          <div class="instagram-link social-link">
            <a href="https://www.instagram.com/fajo_monie" target="_blank">
              <img :src="require('@/assets/images/svg/instagram-icon.svg')" alt="Instagram Icon">
            </a>
          </div>
          <div class="instagram-link social-link">
            <a href="https://web.facebook.com/FaJoMonie/" target="_blank">
              <img :src="require('@/assets/images/svg/facebook-icon.svg')" alt="Facebook Icon">
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
    <fj-page-loader v-else />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import { UserService } from "@/services";
import DashboardBanner from "./DashboardBanner.vue";

export default {
  props: {
    banners: {
      type: Array,
      default: () => ([]),
    },
  },
  
  data() {
    return {
      loading: false,
      activityLogs: [],
    };
  },

  components: {
    DashboardBanner,
  },

  async mounted() {
    await this.fetchRecentActivities();
    await this.fetchNotification();
  },

  computed: {
    ...mapState("user", ["notifications"]),
  },

  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions("history", ["getActivities"]),
    ...mapActions("user", ["getNotification"]),
    ...mapActions("loan", ["suretyApproval", "suretyDisapproval"]),
    getFirstLetters(data) {
      const firstLetters = data
        .split("-")
        .map((word) => word[0])
        .join("");

      return firstLetters;
    },
    async fetchRecentActivities() {
      try {
        this.loading = true;
        let { payload } = await this.getActivities();
        this.activityLogs = payload.rows;
      } catch (err) {
        this.loading = false;
      }
    },
    async fetchNotification() {
      try {
        this.loading = true;
        await this.getNotification({
          type: "surety",
          is_read: false,
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async approveSurety(item) {
      try {
        this.loading = true;
        await this.suretyApproval(item.loan_id);
        await this.markAsRead(item.id);
        this.$store.dispatch("snackbar/openSnackbar", {
          text: "Surety request has been approved",
        });
        this.loading = false;
        await this.fetchNotification();
      } catch (err) {
        this.loading = false;
      }
    },
    async disapproveSurety(item) {
      try {
        this.loading = true;
        await this.suretyDisapproval(item.loan_id);
        await this.markAsRead(item.id);
        this.$store.dispatch("snackbar/openSnackbar", {
          text: "Surety request has been disapproved",
        });
        this.loading = false;
        await this.fetchNotification();
      } catch (err) {
        this.loading = false;
      }
    },
    async markAsRead(id) {
      try {
        this.loading = true;
        await UserService.markAsRead({
          is_read: true,
          notification_id: id,
        });

        this.loading = false;
      } catch (err) {
        this.loading = false;
      } finally {
        setTimeout(() => {
          this.getNotification({
            type: "surety",
            is_read: false,
          });
        }, 600);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activity {
  background-color: #FFF6F2;
  border-radius: 17px;
  box-shadow: 0 4px 10px rgb(0, 0, 0, 0.3);
}

.activity-list {
  padding: 5px;
}

.activity-list-item {
  display: flex;
  padding: 0.4rem;
  border: 1px solid var(--primary);
  border-radius: 10px;
  margin-bottom: 1rem;
  background-color: #fff;
}

.activity-list-icon {
  display: flex;
  align-self: baseline;
  background-color: var(--primary);
  color: #fff;
  border-radius: 50%;
  padding: 0.6rem;
  padding-top: 0.7rem;
  margin-right: 1rem;
  font-weight: 700;
}

.notification-wrapper {
  height: 100%;
  overflow: auto;

  .banner {
    background: var(--indigo);
    border-radius: 5px;
    small {
      font-size: 1rem;
      font-weight: bold;
      display: block;
    }
  }

  .no-notification {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.social-links-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.social-link {
  display: flex;
  margin-right: 1rem;

  a {
    text-decoration: none;
    color: #000000;
  }
}

.social-link:hover {
  cursor: pointer;
}

.whatsapp-link {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 7px;
  padding-left: 17px;
  padding-right: 17px;
  background: #fff;
  border: 0.5px solid hsla(0, 0%, 93%, 1);
  box-shadow: 0 4px 4px rgba($color: #000000, $alpha: 0.25);
}

.whatsapp-link-text {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-right: 2rem;
  text-wrap: nowrap;
}
</style>
