<template>
  <v-carousel class="banner-carousel" cycle>
    <v-carousel-item
      v-for="(image, index) in banners"
      :key="index"
    >
      <div class="banner-content">
        <img
          class="banner-image"
          :src="image"
          alt=""
        />
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  props: {
    banners: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>

<style lang="scss" scoped>
.banner-carousel {
  height: 30vh !important;
}

.banner-content {
  height: 30vh !important;
}

.banner-image {
  height: 100%;
  width: 100%;
  object-fit: fill;
}
</style>