<template>
  <div class="profile-views">
    <div
      class="d-flex flex-column support white justify-center align-center mt-10"
    >
      <h2>Fajo</h2>
      <h3 class="d-block text-center">No 1, Dr Olowookere Str Governor's Road Ikotun, Lagos State.</h3>
      <div class="mt-2">
        <div class="contact-card text-center" :style="{ height: '100px' }">
          <small class="d-block title">Contact Support</small>
          <small class="d-block sub">Phone: 09031341125, 09031584141</small>
          <small class="d-block sub">
            Email:
            <a href="mailto: support@fajomonie.com"> support@fajomonie.com </a>
          </small>
        </div>
      </div>
      <!-- <fj-product-action-icon :ButtonData="actionButton" /> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actionButton: [
        {
          icon: require("@/assets/images/svg/phone-outgoing.svg"),
          text: "Support",
        },
        // {
        //   icon: require("@/assets/images/svg/clock-icon.svg"),
        //   text: "History",
        // },
        // {
        //   icon: require("@/assets/images/svg/corner-up-right.svg"),
        //   text: "Transfer",
        // },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.support {
  height: 60vh;

  small {
    width: 250px;
  }
}
.contact-card {
  small {
    font-size: 1rem;
  }
}
</style>
