<template>
  <div class="pb-5">
    <v-container>
      <div class="d-flex align-center px-3 mb-8">
        <div class="mr-4 pointer" @click="$router.back()">
          <v-img src="@/assets/images/svg/back-arrow.svg" width="20"></v-img>
        </div>
        <h3 class="mb-0">Emergency Funds</h3>
      </div>
      <v-form v-if="loanStatus" v-model="valid" ref="form">
        <v-row>
          <v-col md="5" cols="12">
            <div class="white pa-6">
              <h3 class="mb-4">Request</h3>

              <fj-base-input
                placeholder="This field is optional"
                labelOutside="Surety Phone Number"
                maxLength="11"
                v-model="form.surety_phone_no"
                :rules="suretyRules"
                :loading="verifyingSurety"
                :hint="suretyName"
                persistent-hint
              />
              <fj-base-input
                :placeholder="'Min. value: ' + loanStatus.min_loan_amount"
                labelOutside="Amount"
                type="number"
                v-model="form.amount"
                :rules="amountRules"
              />
              <div class="mb-4 d-flex">
                <small v-if="!suretyName" class="d-block ml-auto">
                  Max Amount:
                  <b>
                    ₦ {{ formatAsMoney(loanStatus.max_loan_amount) }}
                  </b>
                </small>
              </div>

              <label for="">Duration</label>
              <v-row>
                <v-col class="pb-0">
                  <v-select
                    :items="['Months']"
                    placeholder="Years/Months"
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
                <v-col class="pb-0">
                  <fj-base-input
                    placeholder="How long e.g 6"
                    hide-details
                    type="number"
                    v-model="form.duration"
                    :rules="durationRules"
                  />
                </v-col>
              </v-row>

              <!-- <v-select :items="items" label="Frequecy" outlined></v-select> -->

              <!-- <fj-base-input
                placeholder="Enter duration"
                labelOutside="Duration"
                type="number"
                v-model="form.duration"
                :rules="durationRules"
              /> -->
              <small class="d-flex mt-3">
                <v-img
                  width="10"
                  class="mt-1 mr-1 align-self-start"
                  src="@/assets/images/svg/info-icon.svg"
                ></v-img>
                To request for more than what you're eligible to receive, Kindly
                provide your Surety's number (Surety must he registered on Fajo)
              </small>
            </div>

            <div class="white pa-5 mt-5">
              <div class="payment">
                <h3 class="mb-4">Payment details</h3>

                <small for="">Pay to</small>
                <div class="bank mb-4" v-if="account">
                  <h3 class="mb-0">
                    {{ account.account_name }} - {{ account.bank_name }}
                  </h3>
                  <small>{{ account.account_number }}</small>
                </div>

                <small for="">Select card for repayment</small>
                <div
                  class="details mb-4 pointer"
                  v-for="(card, i) in cards"
                  :key="i"
                  @click="selectedCard(card.id)"
                  :class="{ active: form.card_auth_id == card.id }"
                >
                  <h3 class="mb-0">*** *** *** {{ card.last4 }}</h3>
                  <small class="d-block">{{ card.bank }}</small>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="5" class="d-flex flex-column">
            <div class="white pa-5 blur">
              <h3 class="mb-4">Overview</h3>

              <div class="mb-10">
                <div class="d-flex justify-space-between align-center pb-4">
                  <small>Amount</small>
                  <p class="font-weight-bold mb-0">
                    ₦{{ formatAsMoney(form.amount) }}
                  </p>
                </div>

                <div class="d-flex justify-space-between align-center pb-4">
                  <small>Duration</small>
                  <p class="font-weight-bold mb-0">
                    {{ form.duration }} month(s)
                  </p>
                </div>

                <div class="d-flex justify-space-between align-center pb-4">
                  <small>Service Charge</small>
                  <p class="font-weight-bold mb-0" v-if="loanOverview">
                    NGN {{ formatAsMoney(loanOverview.interest) }}
                  </p>
                </div>

                <div class="d-flex justify-space-between align-center pb-4">
                  <small>Emergency Charge</small>
                  <p class="font-weight-bold mb-0" v-if="loanOverview">
                    NGN {{ formatAsMoney(loanOverview.loan_charge) }}
                  </p>
                </div>
                <div class="d-flex justify-space-between align-center pb-4">
                  <small>Net Disburstment</small>
                  <p class="font-weight-bold mb-0" v-if="loanOverview">
                    NGN {{ formatAsMoney(loanOverview.net_disbursement) }}
                  </p>
                </div>
                <div class="d-flex justify-space-between align-center pb-4">
                  <small>Monthly Payment</small>
                  <p class="font-weight-bold mb-0" v-if="loanOverview">
                    NGN {{ formatAsMoney(loanOverview.monthly_payment) }}
                  </p>
                </div>
              </div>

              <small class="note mb-9">
                <b>Note:</b> By Proceeding to payment, you've automatically
                subscribed to Fajo Monie autodebit from your bank account for
                the duration of payment.
              </small>
              <div class="mt-auto">
                <fj-base-button
                  block
                  class="mt-5"
                  :loading="loading"
                  :disabled="!valid || !form.card_auth_id"
                  @click="sendLoanRequest"
                >
                  Request
                </fj-base-button>
              </div>
            </div>
          </v-col>

          <svg id="svg-filter">
            <filter id="svg-blur">
              <feGaussianBlur
                in="SourceGraphic"
                :stdDeviation="!loanOverview ? 4 : 0"
              ></feGaussianBlur>
            </filter>
          </svg>
        </v-row>
      </v-form>
    </v-container>

    <fj-response-modal
      :openModal="openModal"
      status="success"
      title="Request Successful"
      message="Your request has be sent to the admin hold on as you await response as regarding your request"
      callbackUrl="Emergency Funds"
    />
  </div>
</template>

<script>
import { LoanService } from "@/services";
import rules from "@/utils/rules";
import { mapState, mapActions } from "vuex";
import { formatAsMoney } from "@/utils/helpers";
import FjResponseModal from "@/components/modals/FjResponseModal";

export default {
  data() {
    return {
      rules,
      valid: true,
      loading: false,
      openModal: false,
      status: {},
      form: {
        card_auth_id: null,
        amount: null,
        duration: null,
        surety_phone_no: null,
      },
      account: null,
      cards: [],
      verifyingSurety: false,
      suretyName: null,
      suretyRules: [true],
      amountRules: [
        (value) => !!value || "Required",
        (value) =>
          !(value < this.status.min_loan_amount) ||
          "Amount is less than minimum loan amount",
        (value) =>
          !(value > this.status.max_loan_amount) ||
          "Amount is more than maximum loan amount",
      ],
      durationRules: [
        (value) => !!value || "Required",
        (value) =>
          !(value < this.loanStatus.min_duration) ||
          `Duration is minimum ${this.loanStatus.min_duration} months`,
        (value) =>
          !(value > this.loanStatus.max_duration) ||
          `Duration is maximum ${this.loanStatus.max_duration} months`,
      ],
    };
  },
  components: {
    FjResponseModal,
  },
  watch: {
    "form.amount"(val) {
      val && this.form.duration ? this.fetchOverview(this.form) : "";
    },
    "form.surety_phone_no"() {
      this.validateSurety();
    },
    "form.duration"(val) {
      val && this.form.amount ? this.fetchOverview(this.form) : "";
    },
  },
  computed: {
    ...mapState("loan", ["loanStatus", "loanOverview"]),
    isComplete() {
      return this.loanOverview;
    },
  },
  async mounted() {
    await this.fetchCards();
    await this.getBankAccount();
    await this.getLoanStatus();
    this.status = this.loanStatus;
  },

  methods: {
    formatAsMoney,
    ...mapActions("user", ["fetchBankAcct"]),
    ...mapActions("cards", ["getCards"]),
    ...mapActions("loan", ["generateOverview", "getLoanStatus", "requestLoan"]),

    async fetchCards() {
      try {
        this.loading = true;
        let { payload } = await this.getCards();
        this.cards = payload;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    async getBankAccount() {
      try {
        this.loading = true;
        let data = await this.fetchBankAcct();
        this.account = data.rows[0];
        let acctName = data.rows[0].account_name.split(" ");
        this.account.account_name = acctName[0] + " " + acctName[2];
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    fetchOverview() {
      if (this.$refs.form.validate()) {
        this.form.amount = Number(this.form.amount);
        this.form.duration = Number(this.form.duration);
        this.generateOverview(this.form);
      }
    },

    selectedCard(idx) {
      this.form.card_auth_id = idx;
    },

    async sendLoanRequest() {
      try {
        this.loading = true;
        this.form = this.cleanUpFilter(this.form);
        let requestPayload = {
          loan_amount: Number(this.form.amount),
          duration: Number(this.form.duration),
          card_auth_id: this.form.card_auth_id,
          surety_phone_no: this.form.surety_phone_no,
        };
        await this.requestLoan(requestPayload);
        this.openModal = !this.openModal;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    validateSurety() {
      if (this.form.surety_phone_no && this.form.surety_phone_no.length == 11) {
        this.verifyReferral();
        return;
      }
      if (!this.form.surety_phone_no || this.form.surety_phone_no?.length < 11) {
        this.getLoanStatus();
        this.status = this.loanStatus;
        this.suretyName = null;
      }
    },

    // VERIFY SURETY
    async verifyReferral() {
      try {
        this.verifyingSurety = true;
        let { data } = await LoanService.verifySurety(
          this.form.surety_phone_no
        );
        this.verifyingSurety = false;
        this.status = data.payload;
        this.suretyName = data.payload.surety_name;
        this.$store.dispatch("snackbar/openSnackbar", {
          text: "Maximum loan amount has increased",
        });
      } catch (error) {
        this.suretyRules = [];
        this.verifyingSurety = false;
        let surety = this.form.surety_phone_no;
        this.suretyRules.push((v) => !(v == surety) || error.error[0].message);
        // setTimeout(() => {
        //   this.$refs.form.validate();
        // }, 500);
      }
    },

    cleanUpFilter(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === "") {
          delete obj[key];
        }
      });
      let check = Object.keys(obj).length === 0;
      return check ? null : obj;
    },
  },
};
</script>
note{}

<style lang="scss" scoped>
.note {
  line-height: 1.6;
  display: block;
}

.payment {
  .bank {
    width: 100%;
    background: rgba(21, 21, 21, 0.1);
    padding: 14px 24px;
    h3 {
      opacity: 0.5;
      font-size: 15px;
    }
    small {
      opacity: 0.5;
      display: block;
      margin-top: 0px;
    }
  }
  .details {
    width: 100%;
    border: 1px solid #000;
    padding: 14px 24px;

    small {
      display: block;
      margin-top: -6px;
    }

    &.active {
      border: 1px solid var(--primary);
      background: rgba(247, 81, 22, 0.1);
    }
  }
}
.withdrawal {
  .details {
    width: 100%;
    background: rgba(21, 21, 21, 0.1);
    padding: 14px 24px;
    h3 {
      opacity: 0.5;
      font-size: 15px;
    }
    small {
      opacity: 0.5;
      display: block;
      margin-top: 0px;
    }
  }
}

.blur {
  // backdrop-filter: blur(90px);
  -webkit-filter: url(#svg-blur);
  filter: url(#svg-blur);
}
</style>
