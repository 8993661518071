<template>
  <v-container>
    <v-row class="align-center h-100 top-row">
      <v-col></v-col>
      <v-col cols="12" md="5" class="white pa-8">
        <auth-title title="Forgot Pin" :forgotPinPage="true" />
        <div class="mb-6">
          <p>
            Please provide the phone number or email attached to your Fajo
            account
          </p>
        </div>
        <fj-base-input
          v-model="username"
          label-inside="Enter Phone number / Email"
          type="tel"
        />
        <fj-base-button :loading="loading" @click="submit()" block>
          Send me the code
        </fj-base-button>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AuthService } from "@/services";
import AuthTitle from "../components/AuthTitle.vue";
export default {
  components: {
    AuthTitle,
  },
  data: () => ({
    loading: false,
    username: "",
  }),
  methods: {
    async submit() {
      try {
        this.loading = true;
        await AuthService.forgotPassword({
          username: this.username,
        });
        localStorage.setItem("username", this.username);
        this.$store.dispatch("snackbar/openSnackbar", {
          text: "An OTP has been sent to your registered phonenumber",
        });
        this.loading = false;
        setTimeout(() => {
          this.$router.push("/reset-pin");
        }, 500);
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  overflow: auto;
}
</style>
