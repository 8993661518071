<template>
  <div class="h-100">
    <div v-if="history.length > 0" class="activity-feed mt-6">
      <div
        class="d-flex align-center feed-item"
        v-for="(item, index) in history"
        :key="index"
      >
        <div class="details">
          <p class="mb-0">
            {{
              formatWalletType(item.wallet)
            }}
          </p>
          <small>{{ formatDate(item.created_at) }} </small>
        </div>

        <div class="ml-auto">
          <p
            class="mb-0 font-weight-bold secondary--text"
            v-if="item.interest_amount"
          >
            N{{ formatAsMoney(item.interest_amount) }}
          </p>
          <small class="secondary--text">
           credit
          </small>
        </div>
      </div>

      <infinite-loading spinner="waveDots" @infinite="infiniteHandler">
        <span slot="no-results"></span>
        <span slot="no-more"></span>
      </infinite-loading>
    </div>

    <div
      v-if="history.length == 0 && !loading"
      class="d-flex mt-10 h-100 justify-center align-center"
    >
      <p>No Transaction history</p>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      page: 1,
      loading: false,
      history: [],
    };
  },
  computed: {
    ...mapState("history", ["moduleName"]),
    ...mapState(["userDetails"]),
  },
  components: {
    InfiniteLoading,
  },
  async mounted() {
    await this.getInterestHistory()
  },
  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions(["getSavedWallet"]),
    ...mapActions("history", ["getTransactions", "fetchInterestHistory"]),

    formatModuleName(name) {
      return name[0].toUpperCase() + name.slice(1).toLowerCase();
    },

    formatWalletType(wallet) {
      if (wallet && wallet.wallet_type) {
        const type = wallet.wallet_type;
        const name = type[0].toUpperCase() + type.toLowerCase().slice(1);
        return `${name} Interest`;
      }

      return `- Interest`;
    },

    async getInterestHistory() {
      try {
        this.loading = true;
        const { payload } = await this.fetchInterestHistory({
          page: this.page,
        });
        this.history = this.history.concat(payload.rows);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    infiniteHandler($state) {
      setTimeout(async () => {
        this.page++;
        let data = await this.getInterestHistory();
        if (data && data.length > 0) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
