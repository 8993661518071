<template>
  <v-container>
    <div class="d-flex align-center px-3 mb-8">
      <div class="mr-4 pointer" @click="$router.back()">
        <v-img src="@/assets/images/svg/back-arrow.svg" width="20"></v-img>
      </div>
      <h3 class="mb-0">Project Save</h3>
    </div>
    <v-row>
      <v-col cols="12" md="5">
        <div class="withdrawal pa-5 white">
          <h3 class="mb-4">Transfer funds</h3>
           <v-form ref="form" v-model="valid" lazy-validation>
            <fj-base-input
              placeholder="Enter Amount"
              labelOutside="Amount"
              type="number"
              v-model="amount"
              :rules="rules"
            />

            <fj-base-input
              placeholder="Enter Pin"
              labelOutside="PIN"
              v-model="pin"
              :rules="rules"
            />
           </v-form>
          <small for="">From</small>
          <div class="details active mb-4">
            <h3 class="mb-0">
              {{ `${user.customer.first_name} ${user.customer.last_name} ` }} - Fajo Project Save
            </h3>
            <small v-if="projectsaveData" class="d-block"
              >₦{{ formatAsMoney(projectsaveData.amount) }}</small
            >
          </div>

          <small for="">To</small>
          <div class="details mb-4">
            <h3 class="mb-0">
              {{ `${user.customer.first_name} ${user.customer.last_name} ` }} - Fajo Power Save
            </h3>
          </div>

          <fj-base-button
            :disabled="amount == '' || pin == ''"
            :loading="loading"
            @click="initiate()"
            block
            class="mt-5"
          >
            Authorize Transfer
          </fj-base-button>
        </div>

        <!-- <div class="white mt-7 pa-5">
          <withdrawal-otp />
          <v-container>
            <fj-base-button block class=""> Authorize Transfer </fj-base-button>
          </v-container>
        </div> -->
      </v-col>
    </v-row>

    <div class="py-9">
      <fj-response-modal
        :openModal="openModal"
        :status="status"
        :title="title"
        :message="message"
        callbackUrl="Project Save"
      />
    </div>
  </v-container>
</template>

<script>
import FjResponseModal from "../../../components/modals/FjResponseModal.vue";
import { formatAsMoney } from "@/utils/helpers";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    // WithdrawalOtp,
    FjResponseModal,
  },
  data: () => ({
    amount: '',
    pin: '',
    openModal: false,
    valid: true,
    loading: false,
    status: "",
    message: "",
    title: "",
    rules: [
      value => !!value || 'Required',
      value => (value && !value.includes('.')) || 'Please Remove Decimal',
    ],
  }),
  computed: {
    ...mapState({
      projectsaveData: "Projectsave",
      user: "userDetails",
    }),
  },
  async mounted() {
    await this.getProjectsave()
  },
  methods: {
    ...mapActions("projectsave", ["makeTransfer", "getProjectsave"]),
    ...mapActions("powersave", ["getPowersave"]),
    formatAsMoney(money) {
      let Numbermoney = Number(money);
      return formatAsMoney(Numbermoney);
    },
    async initiate() {
      try {
        this.loading = true;
        const data = {
          amount: Number(this.amount),
          pin: this.pin
        }

       await this.makeTransfer(data);
       this.successMessage();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        // this.errorMessage()
      } finally {
        setTimeout(async () => {
          await Promise.all([this.getProjectsave(), this.getPowersave()]);
        }, 3000);
      }
    },
    successMessage() {
      this.openModal = true;
      this.title = "Transfer Successful";
      this.message = `You have successfully transferred NGN${this.amount} 
        from your Fajo Project Saving to your Fajo Power Saving.`;
      this.status = "success";
      this.transactionStatus = "done";
    },
    async errorMessage() {
      this.title = "Something went wrong";
      this.message =
        "Please verify details and try again";
      this.status = "failed";
      this.openModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawal {
  .details {
    width: 100%;
    background: rgba(21, 21, 21, 0.1);
    padding: 14px 24px;
    h3 {
      opacity: 0.5;
      font-size: 15px;
    }
    small {
      opacity: 0.5;
      display: block;
      margin-top: 0px;
    }
  }
}
</style>
