<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent scrollable max-width="400px">
      <v-card class="px-4 py-7">
        <v-card-text>
          <fj-response :status="status" :title="title" :message="message" />
        </v-card-text>
        <fj-base-button
          block
          class="mt-7"
          @click="
            () => {
              dialog = !dialog;
              $router.push({ name: callbackUrl });
            }
          "
        >
          Okay got it
        </fj-base-button>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import FjResponse from "../global/FjResponse.vue";
export default {
  data() {
    return {
      dialog: false,
    };
  },
  components: { FjResponse },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    message: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
    },
    status: {
      type: String,
      required: true,
    },
    callbackUrl: {
      type: String,
      required: true,
    },
  },

  watch: {
    openModal(newval) {
      this.dialog = newval;
    },
  },
};
</script>
