<template>
  <div class="about white  px-6 py-8 mt-10">
    <div class="about-tabs d-flex py-4">
      <p
        class="px-1 mr-4 pointer"
        :class="{ active: active == 't&c' }"
        @click="active = 't&c'"
      >
        Terms & Conditions
      </p>
      <p
        class="px-1 pointer"
        :class="{ active: active == 'policy' }"
        @click="active = 'policy'"
      >
        Privacy Policy
      </p>
    </div>
    <policy v-if="active == 'policy'" />
    <terms-condition v-if="active == 't&c'" />
  </div>
</template>

<script>
import Policy from "../components/Policy";
import TermsCondition from "../components/T&C";
export default {
  data() {
    return {
      active: "t&c",
    };
  },

  components: {
    Policy,
    TermsCondition,
  },
};
</script>

<style lang="scss" scoped>
.about {
  .about-tabs {
    p {
      &.active {
        border-bottom: 2px solid var(--primary);
      }
    }
  }
}
</style>
