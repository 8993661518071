<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card class="px-7 pb-5">
        <v-card-title class="justify-space-between pt-8">
          <h4>KYC</h4>
          <!-- <v-btn icon light @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
        </v-card-title>
        <KycPage @closeModal="dialog = !dialog" />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import KycPage from "../../views/profile/views/kyc.vue";
export default {
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },

  components: {
    KycPage,
  },

  watch: {
    openModal(newval) {
      this.dialog = newval;
    },
  },
};
</script>
