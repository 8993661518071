<template>
  <div class="profile-views">
    <div class="white py-8 px-6 mt-10">
      <p><b>My Cards</b></p>

      <div v-if="!loading" class="payment">
        <v-row>
          <v-col cols="12" md="9">
            <v-row
              v-for="(card, idx) in cards"
              :key="idx"
            >
              <v-col cols="12" md="6" class="d-flex">
                <div
                  class="details d-flex justify-space-between align-center"
                  :class="{ active: card.is_default_card }"
                >
                  <div class="">
                    <h4 class="mb-0">*** *** *** {{ card.last4 }}</h4>
                    <small class="d-block">{{ card.bank }} </small>
                  </div>

                  <small v-if="card.is_default_card" class="primary--text">
                    Default Card
                  </small>
                  <small
                    v-else class="primary--text pointer"
                    @click="updateDefaultCard(card.id)"
                  >
                    Set as default
                  </small>
                </div>
              </v-col>
              <v-col v-if="!card.is_default_card" cols="3" class="delete">
                <div
                  class="red white--text py-5 px-7 text-center pointer"
                  @click="deleteCard(card.id)"
                >
                  Delete Card
                </div>
              </v-col>
              <div v-if="!card.is_default_card" class="cancel">
                <v-icon
                  small color="white darken-2"
                  class="pointer"
                  @click="deleteCard(card.id)"
                >
                  mdi-close-thick
                </v-icon>
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" md="9">
            <v-row>
              <v-col cols="12" md="6">
                <div
                  class="py-5 add-card text-center font-weight-bold pointer"
                  @click="addNewCard"
                >
                  + Add New Card
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <fj-page-loader v-else />
    </div>
    <!-- ============ PAYSTACK CALL BUTTON ========= -->
    <paystack
      :amount="form.amount"
      :email="form.email"
      :paystackkey="this.$paystackKey"
      :reference="form.reference"
      :callback="verify"
      :close="close"
      :channels="form.payment_channel"
    >
      Make Payment
    </paystack>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import paystack from "vue-paystack";
export default {
  data() {
    return {
      form: {
        email: "",
        amount: 0,
        reference: "",
        payment_channel: [],
      },
      cards: [],
      loading: false,
    };
  },
  components: {
    paystack,
  },
  async mounted() {
    await this.fetchCards();
  },
  methods: {
    ...mapActions("cards", [
      "addCards",
      "getCards",
      "setDefaultCard",
      "deleteCardAuth"
    ]),
    ...mapActions("payments", ["verifyPayments"]),

    async addNewCard() {
      try {
        this.loading = true;
        let { payload } = await this.addCards();
        this.form = payload;

        // open up paystack modal
        const paystackInitializer = document.querySelector(".payButton");
        paystackInitializer.click();
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async verify(data) {
      try {
        this.loading = true;
        if (data) {
          await this.verifyPayments({
            reference: data.reference,
          });
          let self = this;
          setTimeout(() => {
            self.fetchCards();
          }, 3000);
        }
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async fetchCards() {
      try {
        this.loading = true;
        let { payload } = await this.getCards();
        this.cards = payload;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async close() {
      try {
        this.loading = true;

        await this.verifyPayments({
          reference: this.form.reference,
        });
        let self = this;
        setTimeout(() => {
          self.fetchCards();
        }, 3000);
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async updateDefaultCard(cardId) {
      this.loading = true;
      try {
        await this.setDefaultCard({
          card_auth_id: cardId
        });
        this.loading = false;
        this.$store.dispatch("snackbar/openSnackbar", {
          text: "Default card updated successfully",
        });
        await this.fetchCards();
      } catch (error) {
        this.loading = false;
      }
    },
    async deleteCard(cardId) {
      this.loading = true;
      try {
        await this.deleteCardAuth(cardId);
        this.loading = false;
        this.$store.dispatch("snackbar/openSnackbar", {
          text: "Card deleted successfully",
        });
        await this.fetchCards();
      } catch (error) {
        this.loading = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../components/style.scss";

.payment {
  .details {
    width: 100%;
    border: 1px solid #000;
    padding: 14px 24px;

    small {
      display: block;
      margin-top: -6px;
      font-size: 0.85rem;
    }

    &.active {
      border: 1px solid var(--primary);
      background: rgba(247, 81, 22, 0.1);
    }
  }

  .add-card {
    border: 1px solid #000;
  }

  .delete {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .cancel {
    position: absolute;
    right: 10px;
    background: #c70606;
    padding: 3px 7px;
    border-radius: 50%;
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
      .v-icon {
        margin-bottom: 2px;
      }
    }
  }
}
</style>
