var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-12"},[(_vm.projectsaveData)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('section',{staticClass:"white py-10",class:!_vm.projectsaveData.active ? 'section-padding' : ''},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"purple--text"},[_vm._v(" ₦"+_vm._s(_vm.displayBalance)+" ")]),(_vm.projectsaveData.active && _vm.projectsaveData.is_autosave)?_c('div',[_c('small',[_vm._v("Start Date")]),_c('br'),_c('small',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.projectsaveData.startDate))+" ")])]):_vm._e()]),(_vm.projectsaveData.active == true)?_c('div',{staticClass:"d-flex justify-space-between mt-4 px-9"},[_c('div',{staticClass:"text-center"},[(_vm.projectsaveData.is_autosave)?_c('div',[_c('small',[_vm._v(_vm._s(_vm.projectsaveData.frequency)+" Saving")]),_c('h5',[_vm._v("₦"+_vm._s(_vm.formatAsMoney(_vm.projectsaveData.sub_amount)))])]):_c('div',[_c('small',[_vm._v("Start Date")]),_c('h5',[_vm._v(_vm._s(_vm.formatDate(_vm.projectsaveData.startDate)))])])]),_c('div',{staticClass:"text-center"},[_c('small',[_vm._v("End Date")]),_c('h5',[_vm._v(_vm._s(_vm.formatDate(_vm.projectsaveData.endDate)))])])]):_vm._e()]),(_vm.projectsaveData.active || _vm.projectsaveData.amount > 0)?_c('fj-base-button',{staticClass:"mt-4",attrs:{"block":""},on:{"click":_vm.moveToWalletTopup}},[_vm._v(" TopUp Wallet ")]):_vm._e(),(!_vm.projectsaveData.active)?_c('fj-base-button',{staticClass:"mt-4",attrs:{"block":""},on:{"click":_vm.startContribution}},[_vm._v(" Start Saving ")]):(
          _vm.projectsaveData.active &&
          _vm.projectsaveData.status == 'in_progress'
        )?_c('fj-base-button',{staticClass:"mt-4",attrs:{"loading":_vm.loading,"block":""},on:{"click":function($event){return _vm.pause()}}},[_vm._v(" Pause Autosave ")]):(
          _vm.projectsaveData.active &&
          _vm.projectsaveData.status == 'paused' &&
          _vm.projectsaveData.is_autosave
        )?_c('fj-base-button',{staticClass:"mt-4",attrs:{"loading":_vm.loading,"block":""},on:{"click":function($event){return _vm.restart()}}},[_vm._v(" Resume Autosave ")]):_vm._e(),(!_vm.projectsaveData.active && _vm.projectsaveData.amount == 0)?_c('fj-base-button',{staticClass:"mt-4",attrs:{"block":"","outlined":""}},[_vm._v(" No active savings ")]):_vm._e(),(_vm.projectsaveData.active)?_c('fj-base-button',{staticClass:"mt-4",attrs:{"block":"","outlined":""}},[_vm._v(" Withdrawal in "+_vm._s(_vm.projectsaveData.noOfDays)+" days ")]):_vm._e(),(!_vm.projectsaveData.active && _vm.projectsaveData.amount > 0)?_c('fj-base-button',{staticClass:"mt-4",attrs:{"block":"","outlined":""},on:{"click":_vm.moveToWithdrawal}},[_vm._v(" Withdraw Funds ")]):_vm._e(),(_vm.projectsaveData.active == false && _vm.projectsaveData.amount > 0)?_c('fj-base-button',{staticClass:"mt-4",attrs:{"block":"","outlined":""},on:{"click":_vm.moveToTransferFunds}},[_vm._v("Transfer funds ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('fj-product-description',{attrs:{"text":"Save daily, weekly or monthly with \n        Lock period of 120days. 12% ROI per annum. Interest paid daily.","color":"purple","icon":require('@/assets/images/svg/product-thumb-up.svg')}}),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"contact-card pa-5",style:({ height: '100px' })},[_c('small',{staticClass:"d-block title"},[_vm._v("Contact Support")]),_c('small',{staticClass:"d-block sub"},[_vm._v("Phone: 09031341125")]),_c('small',{staticClass:"d-block sub"},[_vm._v(" Email: "),_c('a',{attrs:{"href":"mailto: support@fajomonie.com"}},[_vm._v(" support@fajomonie.com ")])])])])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('history')],1)],1):_c('fj-page-loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }