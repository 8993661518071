<template>
  <v-container class="pb-7">
    <div class="d-flex align-center px-3 mb-8">
      <div class="mr-4 pointer" @click="$router.back()">
        <v-img src="@/assets/images/svg/back-arrow.svg" width="20"></v-img>
      </div>
      <h3 class="mb-0">Emergency Funds</h3>
    </div>
    <v-row v-if="loanStatus">
      <v-col md="5" cols="12">
        <div class="payment">
          <div class="white pa-5">
            <h3 class="mb-4">Make Payment</h3>
            <fj-base-input
              placeholder="Min. value: N1000"
              labelOutside="Amount"
              type="number"
              v-model="amountInput"
            />
          </div>

          <div class="white pa-5 mt-5">
            <small>Select card for repayment</small>
            <div
              class="details mb-4 pointer"
              v-for="(card, i) in cards"
              :key="i"
              @click="selectedCard(card.id)"
              :class="{ active: selected == card.id }"
            >
              <h3 class="mb-0">*** *** *** {{ card.last4 }}</h3>
              <small class="d-block">{{ card.bank }}</small>
            </div>

            <div
              @click="selectedCard('new')"
              :class="{ active: selected == 'new' }"
              class="py-5 text-center details font-weight-bold mb-4"
            >
              Pay with new card
            </div>
          </div>
          <fj-base-button
            block
            class="mt-5"
            :loading="loading"
            :disabled="selected == null"
            @click="validateForm"
          >
            Make Payment
          </fj-base-button>
        </div>
      </v-col>
    </v-row>
    <fj-page-loader v-else />

    <!-- ============ PAYSTACK CALL BUTTON ========= -->
    <paystack
      :amount="Number(amount)"
      :email="email"
      :paystackkey="this.$paystackKey"
      :reference="reference"
      :channels="channels"
      :callback="verify"
      :close="close"
    >
      Make Payment
    </paystack>

    <div class="py-9">
      <fj-response-modal
        :openModal="openModal"
        :status="status"
        :title="title"
        :message="message"
        callbackUrl="Emergency Funds"
      />
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FjResponseModal from "../../../components/modals/FjResponseModal";
import paystack from "vue-paystack";

export default {
  data() {
    return {
      openModal: false,
      loading: false,
      amountInput: null,
      selected: null,
      cards: [],
      options: ["payonline", "paybycard"],
      status: "",
      message: "",
      title: "",
      amount: 0,
      reference: "",
      email: "",
      channels: [],
    };
  },
  components: {
    FjResponseModal,
    paystack,
  },
  computed: {
    ...mapState("loan", ["loanStatus"]),
    paymentMethod() {
      let methods;
      if (this.selected == "new") {
        methods = this.options[0];
      } else {
        methods = this.options[1];
      }
      return methods;
    },
  },
  async mounted() {
    await this.fetchCards();
    await this.getLoanStatus();
  },
  methods: {
    ...mapActions("cards", ["getCards"]),
    ...mapActions("loan", ["getLoanStatus", "initiateLoanPayment"]),
     ...mapActions("payments", ["verifyPayments"]),
    selectedCard(idx) {
      this.selected = idx;
    },

    async fetchCards() {
      try {
        this.loading = true;
        let { payload } = await this.getCards();
        this.cards = payload;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    async validateForm() {
      if (!this.amountInput) {
        this.$store.dispatch("snackbar/openSnackbar", {
          text: "Please enter amount",
        });
      } else {
        await this.initiatePayment();
      }
    },

    async initiatePayment() {
      try {
        this.loading = true;
        let payload = {
          loan_id: this.loanStatus.ongoing_loan.id,
          amount: Number(this.amountInput),
          request: this.paymentMethod,
          card_auth_id: this.selected == "new" ? null : this.selected,
        };
        const data = await this.initiateLoanPayment(payload);
        if (this.paymentMethod == this.options[1]) {
          this.verify();
        } else if (this.paymentMethod == this.options[0]) {
          this.initializePaystack(data);
        }
        this.loading = false;
      } catch (error) {
        this.errorMessage();
        this.loading = false;
      }
    },

    async initializePaystack(data) {
      const paystackInitializer = document.querySelector(".payButton");
      this.reference = data.payload.reference;
      this.amount = data.payload.amount;
      this.email = data.payload.email;
      this.channels = data.payload.payment_channel;
      paystackInitializer.click();
    },

    async verify(data) {
      try {
        if (data) {
          await this.verifyPayments({
            reference: data.reference,
          });
        }
        this.successMessage();
      } catch (error) {
        this.errorMessage();
      } finally {
        let self = this;
        setTimeout(() => {
          self.getLoanStatus();
          self.$store.dispatch("user/fetchUser");
        }, 4000);
      }
    },

    async close() {
      try {
        await this.verifyPayments({
          reference: this.reference,
        });
        this.errorMessage();
      } catch (error) {
        this.errorMessage();
      } finally {
        let self = this;
        setTimeout(() => {
          self.getLoanStatus();
          self.$store.dispatch("user/fetchUser");
        }, 4000);
      }
    },

    async successMessage() {
      this.title = "Transaction Successful";
      this.message =
        "Your payment has been received, check transaction history to view";
      this.status = "success";
      this.openModal = true;
    },

    async errorMessage() {
      this.title = "Something went wrong";
      this.message =
        "Your payment failed, please verify details and try again";
      this.status = "failed";
      this.openModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  .details {
    width: 100%;
    border: 1px solid #000;
    padding: 14px 24px;

    small {
      display: block;
      margin-top: -6px;
    }

    &.active {
      border: 1px solid var(--primary);
      background: rgba(247, 81, 22, 0.1);
    }

    &.account {
      background: rgba(0, 0, 0, 0.1);

      a {
        text-decoration: none;
      }
    }
  }
}
</style>
