<template>
  <v-container>
    <v-row class="align-center h-100 top-row">
      <v-col></v-col>
      <v-col cols="12" sm="8" md="7" lg="5" class="pa-8 white">
        <div v-if="status === 'verifying'">
          <auth-title title="Verify phone number" />
          <div class="">
            <p class="mb-3" v-if="registeredUser">
              We’ve sent a One-Time Password to your phone number
              {{ registeredUser.auth ? registeredUser.auth.phone_no : "" }}
            </p>
          </div>
          <fj-base-input v-model="otp" label-inside="Enter OTP" />
          <fj-base-button
            :loading="loading"
            @click="submit()"
            block
            class="mt-5"
          >
            Verify OTP
          </fj-base-button>
          <div class="text-center mt-12">
            <p>
              Didn’t receive OTP?
              <span @click="resendOtp()" class="dark--text text-underline">
                Resend
              </span>
            </p>
            <p>
              Incorrect phone number?
              <router-link to="/change-number" class="dark--text">
                Change phone number
              </router-link>
            </p>
          </div>
        </div>
        <div v-if="status === 'verified'">
          <auth-title title=""></auth-title>
          <v-img src="@/assets/images/svg/hurray.svg" width="40"></v-img>
          <h3 class="pt-3 pb-1">Yaaayyy!!!</h3>
          <div class="mb-6">
            <p>
              Hello{{
                registeredUser.auth
                  ? " " + registeredUser.auth.customer.full_name
                  : ""
              }}, we’re delighted to have you onboard! Your account has been
              sucessfully created, you can now login to your account and explore
              our pages.
            </p>
          </div>

          <fj-base-button @click="$router.push('/login')" block>
            Proceed to login
          </fj-base-button>
        </div>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthTitle from "../components/AuthTitle.vue";
import { mapActions } from "vuex";
import { AuthService } from "@/services";

export default {
  components: {
    AuthTitle,
  },
  data: () => ({
    otp: "",
    loading: false,
    status: "verifying",
    registeredUser: {},
  }),
  mounted() {
    const savedUser = JSON.parse(localStorage.getItem("RegisteredUser"));
    this.registeredUser = savedUser ?? this.registeredUser;
  },
  methods: {
    // verifyAccount
    ...mapActions("auth", ["verifyAccount"]),
    async submit() {
      this.loading = true;
      try {
        let form = {
          otp: this.otp,
          auth_id: this.registeredUser.auth.id,
        };
        await this.verifyAccount(form);
        this.status = "verified";
        this.loading = false;
        // this.$router.push("/feed-back");
      } catch (error) {
        this.loading = false;
      }
    },

    async resendOtp() {
      try {
        let payload = {
          purpose: "ACCOUNT_VERIFICATION",
          username: this.registeredUser.auth.phone_no,
        };
        await AuthService.resendOtp(payload);
        this.$store.dispatch("snackbar/openSnackbar", {
          text: `OTP has been resent to ${this.registeredUser.auth.phone_no}`,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 90vh;
  overflow: auto;

  .top-row {
    min-height: 90vh;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .text-underline {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
