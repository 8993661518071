<template>
  <div class="mt-12">
    <v-row v-if="wallet">
      <v-col cols="12" md="4">
        <section
          class="white py-10"
          :class="!wallet.active ? 'section-padding' : ''"
        >
          <div class="text-center">
            <h1 class="thrift-color__text">
              ₦{{ wallet.amount > 0 ? formatAsMoney(wallet.amount) : "0.00" }}
            </h1>
            <!-- <small>Save as low N200, earn 15% daily</small> -->
            <small v-if="wallet.active == true">Start Date</small>
            <br />
            <small v-if="wallet.active == true" class="text-bold">{{
              formatDate(wallet.startDate)
            }}</small>
          </div>

          <div
            v-if="wallet.active == true"
            class="d-flex justify-space-between mt-4 px-9"
          >
            <div class="text-center">
              <small class="text-capitalize"
                >{{ wallet.frequency }} savings</small
              >
              <h5>₦{{ formatAsMoney(wallet.sub_amount) }}</h5>
            </div>

            <div class="text-center">
              <small>Withdrawal Date</small>
              <h5>{{ formatDate(wallet.withdrawalDate) }}</h5>
            </div>
          </div>
        </section>

        <fj-base-button
          block
          class="mt-4"
          @click="moveToWalletTopup"
        >
          TopUp Wallet
        </fj-base-button>

        <fj-base-button
          v-if="wallet.active == false"
          block
          class="mt-4"
          :disabled="withdrawalOngoing()"
          @click="startContribution"
        >
          Start Contribution
        </fj-base-button>
        <fj-base-button
          block
          class="mt-4"
          @click="pause()"
          :loading="loading"
          v-else-if="wallet.active == true && wallet.status == 'in_progress'"
        >
          Stop Contribution
        </fj-base-button>
        <fj-base-button
          block
          class="mt-4"
          @click="restart()"
          :loading="loading"
          v-else-if="wallet.active == true && wallet.status == 'paused'"
          >Resume Contribution</fj-base-button
        >
        <fj-base-button
          v-if="wallet.active == true || withdrawalOngoing()"
          block
          outlined
          class="mt-4"
          :class="
            isWithdrawalDay() || withdrawalOngoing()
              ? 'withdrawal-processing'
              : ''
          "
          >{{ displayWithdrawalText(wallet.noOfDays) }}
        </fj-base-button>
      </v-col>
      <v-col cols="12" md="4">
        <fj-product-description
          text="Save as little as N1,000 over 30 days. One day’s contribution is 
          Fajo commission. Maximum daily savings cannot be more than N2,500"
          color="thrift-color"
          :icon="require('@/assets/images/svg/stop-circle.svg')"
        />

        <div class="mt-5">
          <div class="contact-card pa-5" :style="{ height: '100px' }">
            <small class="d-block title">Contact Support</small>
            <small class="d-block sub">Phone: 09031341125</small>
            <small class="d-block sub">
              Email:
              <a href="mailto: support@fajomonie.com">
                support@fajomonie.com
              </a>
            </small>
          </div>
        </div>
      </v-col>

      <v-col>
        <history></history>
      </v-col>
    </v-row>
    <fj-page-loader v-else />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import history from "@/components/history/history.vue";
export default {
  data() {
    return {
      sheet: false,
      loading: false,
    };
  },

  components: {
    history,
  },

  async mounted() {
    await this.saveModule("AJO");
    await this.getThriftflex();
  },

  computed: {
    ...mapState({
      wallet: "Thriftflex",
    }),
    ...mapState(["userDetails"]),
    ...mapState("history", ["moduleName"]),
  },

  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions("history", ["saveModule"]),
    ...mapActions("thriftflex", [
      "getThriftflex",
      "pauseThriftflex",
      "restartThriftflex",
    ]),

    async pause() {
      try {
        this.loading = true;
        await this.pauseThriftflex({
          sub_id: Number(this.wallet.subid),
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async restart() {
      try {
        this.loading = true;
        await this.restartThriftflex({
          sub_id: Number(this.wallet.subid),
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    startContribution() {
      this.$router.push({ name: "Tf-Contribution" });
    },

    moveToWalletTopup() {
      this.$store.dispatch('wallet/setWalletDetails', 'AJO');
      this.$router.push({ name: "Tf-Topup" });
    },

    withdrawalOngoing() {
      const ongoing = ["pending", "approved", "disbursement_failed"];
      if (
        this.wallet &&
        this.wallet.withdrawStatus &&
        ongoing.includes(this.wallet.withdrawStatus)
      ) {
        return true;
      }
      return false;
    },

    isWithdrawalDay() {
      if (this.wallet) {
        const withdrawalDate = new Date(this.wallet.withdrawalDate);
        const today = new Date();
        return withdrawalDate.toDateString() === today.toDateString();
      }
    },

    displayWithdrawalText(noOfDays) {
      return noOfDays > 0
        ? `Withdrawal in ${noOfDays} days`
        : "Processing Withdrawal";
    },
  },
};
</script>
<style lang="scss" scoped>
.thrift-color {
  background: #206d5f;

  &__text {
    color: #206d5f;
    font-size: 2rem;
  }
}

.activity-icon {
  padding: 9px 12px;
  border-radius: 30px;
  color: #fff;
  font-size: 17px;
  //   background: var(--primary);
}

.history {
  height: 72vh;
  overflow: auto;

  @media screen and (max-width: 768px) {
    height: 50vh;
  }
}

.withdrawal-processing {
  border-color: #c4c4c4;
  color: #888484 !important;
}

.section-padding {
  .text-center {
    display: flex;
    justify-content: center;
  }
}

.contact-card {
  width: 100%;
  position: relative;
  background-color: #fff;

  .title {
    font-size: 1rem;
  }
}
</style>
