<template>
  <div>
    <a v-if="loginPage" href="https://fajomonie.com">
      <v-img
        src="@/assets/images/png/fajo-logo-2.png"
        width="130"
        height="56"
      />
    </a>
    <v-img
      v-else
      src="@/assets/images/png/fajo-logo-2.png"
      width="130"
      height="56"
    />
    <h2 class="mt-10 mb-2">
      <img
        class="back-arrow"
        @click="() => $router.push({ name: 'Login' })"
        v-if="forgotPinPage"
        src="@/assets/images/svg/arrow-left.svg"
        alt=""
      />
      {{ title }}
    </h2>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    loginPage: {
      type: Boolean,
      default: false,
    },
    forgotPinPage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.back-arrow {
  cursor: pointer;
  display: block;
  margin-bottom: 1rem;
}
</style>
