<template>
  <div class="h-100">
    <div v-if="history.length > 0" class="activity-feed mt-6">
      <div
        class="d-flex align-center feed-item"
        v-for="(item, index) in history"
        :key="index"
      >
        <div class="details">
          <p class="mb-0">
            {{ formatWallletType(item.wallet) }}
          </p>
          <small>{{ formatDate(item.created_at) }} </small>
        </div>

        <div class="ml-auto">
          <p class="mb-0 font-weight-bold" v-if="item.amount_requested">
            N{{ formatAsMoney(item.amount_requested) }}
          </p>
          <small
            class="secondary--text history-status"
            :class="
              item.status === 'declined' ? 'error--text' : 'secondary--text'
            "
            v-if="item.amount_requested"
          >
            {{ formatStatus(item.status) }}
          </small>
        </div>
      </div>

      <infinite-loading spinner="waveDots" @infinite="infiniteHandler">
        <span slot="no-results"></span>
        <span slot="no-more"></span>
      </infinite-loading>
    </div>

    <div
      v-if="history.length == 0 && !loading"
      class="d-flex mt-10 h-100 justify-center align-center"
    >
      <p>No Withdrawal history</p>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      page: 1,
      loading: false,
      history: [],
    };
  },
  computed: {
    ...mapState("history", ["moduleName"]),
  },
  components: {
    InfiniteLoading,
  },
  async mounted() {
    await this.fetchWithdrawals();
  },
  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions(["getSavedWallet"]),
    ...mapActions("history", ["getWithdrawalHistory"]),

    formatModuleName(name) {
      return name[0].toUpperCase() + name.slice(1).toLowerCase();
    },

    formatWallletType(wallet) {
      if (wallet && wallet.wallet_type) {
        const type = wallet.wallet_type;
        let name = type[0] + type.toLowerCase().slice(1);
        name = name === "Commission" ? "Rewards" : name;
        return `${name} Withdrawal`;
      }

      return `- Withdrawal`;
    },

    formatStatus(status) {
      if (status === "approved" || status === "disbursement_failed") {
        return "pending";
      }

      if (status === "processed") {
        return "credited";
      }

      return status;
    },

    async fetchWithdrawals() {
      try {
        this.loading = true;
        const wallet = await this.getSavedWallet(this.moduleName);
        let { payload } = await this.getWithdrawalHistory({
          wallet_id: Number(wallet.id),
          page: this.page,
        });
        this.history = this.history.concat(payload.rows);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    infiniteHandler($state) {
      setTimeout(async () => {
        this.page++;
        let data = await this.fetchWithdrawals();
        if (data && data.length > 0) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
