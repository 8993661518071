<template>
  <div class="h-100">
    <div v-if="history.length > 0" class="activity-feed mt-6">
      <div
        class="d-flex align-center feed-item"
        v-for="(item, index) in history"
        :key="index"
      >
        <div class="details">
          <p class="mb-0">Request</p>
          <small>{{ formatDate(item.created_at) }} </small>
        </div>

        <div class="ml-auto">
          <p
            class="mb-0 font-weight-bold"
            :class="amountDisplayColor(item.status)"
          >
            N{{ formatAsMoney(item.loan_amount) }}
          </p>
          <small :class="amountDisplayColor(item.status)">
            {{ formatStatus(item.status) }}
          </small>
        </div>
      </div>

      <infinite-loading spinner="waveDots" @infinite="infiniteHandler">
        <span slot="no-results"></span>
        <span slot="no-more"></span>
      </infinite-loading>
    </div>

    <div
      v-if="history.length == 0 && !loading"
      class="d-flex mt-10 h-100 justify-center align-center"
    >
      <p>No History</p>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      page: 1,
      loading: false,
      history: [],
    };
  },
  computed: {
    ...mapState("history", ["moduleName"]),
  },
  components: {
    InfiniteLoading,
  },
  async mounted() {
    await this.loadLoanHistory();
  },
  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions(["getSavedWallet"]),
    ...mapActions("history", ["getTransactions", "getLoanHistory"]),

    async loadLoanHistory() {
      try {
        this.loading = true;
        const { rows } = await this.getLoanHistory({
          page: this.page
        });
        this.history = this.history.concat(rows);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    formatStatus(status) {
      let text = status;
      if (status === "processing" || status === "disbursement_failed") {
        text = "pending";
      }

      if (status === "disbursed") text = "ongoing";
      return text;
    },

    amountDisplayColor(status) {
      return status === "declined" || status === "defaulted"
        ? "error--text"
        : "secondary--text";
    },

    infiniteHandler($state) {
      setTimeout(async () => {
        this.page++;
        let data = await this.loadLoanHistory();
        if (data && data.length > 0) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
