<template>
  <div>
    <v-container>
      <div class="page-title mb-4">
        <h3 class="text-capitalize">
          Welcome back {{ userDetails.customer.first_name }}!
        </h3>
      </div>
      <dashboard-cards />
      <div
        v-if="banners && banners.length > 0"
        class="mt-7 d-block d-md-none"
      >
        <DashboardBanner :banners="banners"></DashboardBanner>
      </div>
      <new-portfolio-cards />
      <new-recent-activity :banners="banners" />
    </v-container>
    <kyc-modal :openModal="openKycModal" />
  </div>
</template>

<script>
import KycModal from "../../components/modals/KycModal.vue";
// import PackagesSlides from "./components/PackagesSlides";
import DashboardCards from "./components/DashboardCards.vue";
import DashboardBanner from "./components/DashboardBanner.vue";
// import PortfolioCards from "../portfolio/components/PortfolioCards";
import NewPortfolioCards from "../portfolio/components/NewPortfolioCards";
// import RecentActivity from "./components/RecentActivity";
import NewRecentActivity from "./components/NewRecentActivity";
import { UserService } from "@/services";

import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      openKycModal: false,
      banners: []
    };
  },
  components: {
    DashboardBanner,
    DashboardCards,
    KycModal,
    // PackagesSlides,
    NewPortfolioCards,
    NewRecentActivity,
    // PortfolioCards,
    // RecentActivity,
  },

  async mounted() {
    this.fetchDashboardBanner()
    if (!this.userDetails.customer.kyc_complete) {
      this.openKycModal = !this.openKycModal;
    }
    await this.fetchUser()
  },
  computed: {
    ...mapState(["userDetails"]),
  },
  methods: {
   ...mapActions("user", ["fetchUser"]),
    async fetchDashboardBanner() {
      try {
        const { payload } = await UserService.fetchAllBanners();
        this.banners = payload.banner;
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Space Grotesk";
}
</style>
