<template>
  <v-container class="pb-7">
    <div class="d-flex align-center px-3 mb-8">
      <div class="mr-4 pointer" @click="$router.back()">
        <v-img src="@/assets/images/svg/back-arrow.svg" width="20"></v-img>
      </div>
      <h3 class="mb-0">Fund Wallet</h3>
    </div>
    <v-row v-if="!loading">
      <v-col md="5" cols="12">
        <div class="payment">
          <div class="white pa-5">
            <!-- <h3 class="mb-4">Make Payment</h3> -->
            <v-form ref="form" v-model="valid" lazy-validation>
              <fj-base-input
                placeholder="Enter Amount"
                labelOutside="Amount"
                type="number"
                v-model="amount"
                :rules="rules"
              />
            </v-form>
          </div>

          <div class="white pa-5 mt-5">
            <small>Select Payment Method:</small>
            <div
              class="details mb-4 pointer"
              v-for="(card, i) in cards"
              :key="i"
              @click="selectedCard(card.id)"
              :class="{ active: selected == card.id }"
            >
              <h3 class="mb-0">*** *** *** {{ card.last4 }}</h3>
              <small class="d-block">{{ card.bank }}</small>
            </div>

            <div
              @click="selectedCard('new')"
              :class="{ active: selected == 'new' }"
              class="py-5 text-center details font-weight-bold mb-4"
            >
              Pay via Paystack
            </div>
          </div>
          <fj-base-button
            block
            class="mt-5"
            :loading="loading"
            :disabled="!valid || selected == null"
            @click="fundWallet"
          >
            Pay Now
          </fj-base-button>
        </div>
      </v-col>
    </v-row>
    <fj-page-loader v-else />

    <!-- ============ PAYSTACK CALL BUTTON ========= -->
    <paystack
      :amount="payment.amount"
      :email="payment.email"
      :paystackkey="this.$paystackKey"
      :reference="payment.reference"
      :channels="payment.payment_channel"
      :callback="verify"
      :close="close"
    >
      Make Payment
    </paystack>

    <div class="py-9">
      <fj-response-modal
        :openModal="openModal"
        :status="status"
        :title="title"
        :message="message"
        :callbackUrl="nextPage"
      />
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FjResponseModal from "./modals/FjResponseModal";
import paystack from "vue-paystack";

export default {
  data() {
    return {
      valid: true,
      openModal: false,
      loading: false,
      amount: null,
      selected: null,
      cards: [],
      options: ["payonline", "paybycard"],
      status: "",
      message: "",
      title: "",
      channels: [],
      payment: {
        amount: 0,
        email: '',
        reference: '',
        payment_channel: [],
        callback_url: ''
      },
      rules: [
        value => !!value || 'Required',
        value => (value && !value.includes('.')) || 'Please Remove Decimal',
      ],
    };
  },
  components: {
    FjResponseModal,
    paystack,
  },
  computed: {
    ...mapGetters({
      wallet: 'wallet/walletDetails',
    }),
    paymentMethod() {
      let methods;
      if (this.selected == "new") {
        methods = this.options[0];
      } else {
        methods = this.options[1];
      }
      return methods;
    },
    nextPage() {
      const name = this.wallet.wallet_type;
      const pages = {
        'POWERSAVE': 'Power Save',
        'PROJECTSAVE': 'Project Save',
        'AJO': 'Thrift Flex',
      };
      return pages[name];
    },
  },
  async mounted() {
    await this.fetchCards();
  },
  methods: {
    ...mapActions("cards", ["getCards"]),
    ...mapActions("wallet", ["topupWallet"]),
    ...mapActions("payments", ["verifyPayments"]),
    selectedCard(idx) {
      this.selected = idx;
    },
    async fetchCards() {
      try {
        this.loading = true;
        let { payload } = await this.getCards();
        this.cards = payload;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async fundWallet() {
      try {
        this.loading = true;
        let data = {
          wallet_id: this.wallet.id,
          amount: Number(this.amount),
          request: this.paymentMethod,
          card_auth_id: this.selected == "new" ? null : this.selected,
        };
        const { payload } = await this.topupWallet(data);
        if (this.paymentMethod === this.options[0]) {
          this.payment = {
            ...this.payment,
            ...payload,
          }
          await this.initializePaystack();
        } 
        else if (this.paymentMethod === this.options[1]) {
          await this.verify();
        }
        this.loading = false;
      } catch (error) {
        this.errorMessage();
        this.loading = false;
      }
    },

    async initializePaystack() {
      const paystackInitializer = document.querySelector(".payButton");
      paystackInitializer.click();
    },

    async verify(data) {
      try {
        if (data) {
          await this.verifyPayments({
            reference: data.reference,
          });
        }
        this.successMessage();
      } catch (error) {
        this.errorMessage();
      } finally {
        this.processDone()
      }
    },
    async close() {
      try {
        await this.verifyPayments({
          reference: this.payment.reference,
        });
        this.errorMessage();
      } catch (error) {
        this.errorMessage();
      } finally {
        this.processDone()
      }
    },
    processDone() {
      const self = this;
      setTimeout(() => {
        self.$store.dispatch("user/fetchUser");
        if (self.wallet.wallet_type == 'POWERSAVE') {
          self.$store.dispatch("powersave/getPowersave");
        }
        if (self.wallet.wallet_type == 'PROJECTSAVE') {
          self.$store.dispatch("projectsave/getProjectsave");
        }
        if (self.wallet.wallet_type == 'AJO') {
          self.$store.dispatch("thriftflex/getThriftflex");
        }
      }, 4000);
    },
    async successMessage() {
      this.title = "Transaction Successful";
      this.message =
        "Your wallet has been credited successfully";
      this.status = "success";
      this.openModal = true;
    },

    async errorMessage() {
      this.title = "Something went wrong";
      this.message =
        "Please verify details and try again";
      this.status = "failed";
      this.openModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  .details {
    width: 100%;
    border: 1px solid #000;
    padding: 14px 24px;

    small {
      display: block;
      margin-top: -6px;
    }

    &.active {
      border: 1px solid var(--primary);
      background: rgba(247, 81, 22, 0.1);
    }

    &.account {
      background: rgba(0, 0, 0, 0.1);

      a {
        text-decoration: none;
      }
    }
  }
}
</style>
